import React, { useEffect, useState } from "react";
import Logo from "assets/images/general/logo-dark.svg";
import ErrorComponent from "components/ErrorComponent";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  authState,
  getUserBusinessesList,
  isUserHasCognitoToken,
} from "recoil/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
import { LoginResponse } from "views/auth/utils";
import { useTranslation } from "react-i18next";

function ChooseBusiness() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLogIn = useRecoilValue(isUserHasCognitoToken);
  const businessesList = useRecoilValue(getUserBusinessesList);
  const [cognitoToken, setCognitoToken] = useState("");
  const setAuthSettingsDetail = useSetRecoilState(authState);

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    if (!isUserLogIn) {
      navigate("/login");
    }
  }, [isUserLogIn, navigate]);

  const selectBusiness = async (id) => {
    setSelectedBusiness(id);
    let result = await CallAPI(
      "SELECT_BUSINESS",
      {
        access_token: cognitoToken,
        business_id: id,
      },
      null,
      setLoader,
      setApiError,
      null
    );

    if (result.status) {
      const authObject = LoginResponse(result.data);
      setAuthSettingsDetail(authObject);
    }
  };
  useEffect(() => {
    if (location.state && location.state.token) {
      setCognitoToken(location.state.token);
    }
  }, [location]);
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="non-auth-header">
        <h2 className="theme-font-inter-bold">
          {t("texts.welcome_to_black_force")}
        </h2>
        <span className="theme-font-inter-regular">{t("texts.select_company")}</span>
      </div>
      <div className="form mb-4 box-width">
        {apiError && <ErrorComponent text={t(apiError)} variant={"danger"} />}
        {businessesList.map((singleBusiness) => {
          return (
            <div
              key={singleBusiness.id}
              className="business-item cursor-pointer"
              onClick={() => selectBusiness(singleBusiness.id)}
            >
              <span className="theme-font-inter-medium">
                {singleBusiness.name}
                {selectedBusiness === singleBusiness.id && loader ? (
                  <div className="spinner-border"></div>
                ) : null}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChooseBusiness;
