export const SuperAdminSubscriptionTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  {
    key: "price",
    displayValue: "price",
    sort: true,
    callbackFunction: "displayPriceWithDollar",
  },
  { key: "type", displayValue: "subscription_type", sort: true },
  { key: "credits", displayValue: "credits", sort: true },
  {
    key: "credits_expires",
    displayValue: "credits_expires",
    sort: true,
    callbackFunction: "displayCreditsExpired",
  },
  {
    key: "auto_charged",
    displayValue: "auto_charged",
    sort: true,
    callbackFunction: "displayAutoCharged",
  },
];
export const StudentSubscriptionTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  {
    key: "type",
    displayValue: "subscription_type",
    sort: true,
    callbackFunction: "displaySubscriptionType",
  },
  {
    key: "total_amount",
    displayValue: "price",
    sort: true,
    callbackFunction: "displayPriceWithDollar",
  },
  { key: "coupon_code", displayValue: "coupon_code", sort: true },
  {
    key: "discounted_amount",
    displayValue: "discounted_amount",
    sort: true,
    callbackFunction: "displayPriceWithDollar",
  },
  {
    key: "purchased_date",
    displayValue: "purchased_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "end_date",
    displayValue: "end_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "is_auto_renewal",
    displayValue: "is_auto_renewal",
    sort: true,
    callbackFunction: "displayAutoCharged",
  },
];
