import React, { useEffect, useState } from "react";
import Logo from "assets/images/general/logo-dark.svg";
import TextComponent from "components/TextComponent";
import PasswordComponent from "components/PasswordComponent";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { authState } from "recoil/Auth";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CallAPI } from "actions/General";
import { UserLoginSchema } from "./FieldValidationRoles";
import ErrorComponent from "components/ErrorComponent";
import { LoginResponse } from "views/auth/utils";
import { useTranslation } from "react-i18next";

function Login() {
  const errorsInitialState = { userName: "", password: "" };

  const { t } = useTranslation();
  const [authRecoilState, setAuthRecoilState] = useRecoilState(authState);
  const setAuthSettingsDetail = useSetRecoilState(authState);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    userName: "dilpazirwhizpool",
    password: "Maz!23456",
  });
  const [errors, setErrors] = useState(errorsInitialState);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const loginAction = async () => {
    try {
      setLoaderType("login");
      setErrors(errorsInitialState);
      await UserLoginSchema.validate(values, { abortEarly: false });
      setLoader(true);
      let result = await CallAPI(
        "USER_LOGIN",
        values,
        null,
        setLoader,
        setApiError,
        null
      );

      if (result.status) {
        setAuthRecoilState({
          ...authRecoilState,
          cognitoToken: result.data.idToken,
          businessesList: result.data.businessesList,
        });
        navigate("/choose_business", {
          relative: "path",
          state: { token: result.data.accessToken },
        });
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };
  const SingleLogin = async (data) => {
    setLoaderType("single_login");
    let result = await CallAPI(
      "LOGIN_VIA_SINGLE_LOGIN",
      data,
      null,
      setLoader,
      setApiError,
      null
    );

    if (result.status) {
      const authObject = LoginResponse(result.data);
      setAuthSettingsDetail(authObject);
    }
  };
  const loginSingleLoginAction = () => {
    window.open(process.env.REACT_APP_LOGIN_URL, "", "width=700,height=700");
  };

  const getIsSSoEnabled = async () => {
    const result = await CallAPI(
      "GET_IS_SSO_ENABLED",
      null,
      null,
      null,
      null,
      null
    );
    if (result.status) {
      setIsSSOEnabled(result.data ? result.data.sso_enabled : false);
    }
  };

  useEffect(() => {
    getIsSSoEnabled();
  }, []);
  useEffect(() => {
    window.addEventListener("message", handleEvent, false);
    return () => {
      window.removeEventListener("message", handleEvent, false);
    };
  }, []);

  const handleEvent = async (event) => {
    let url = process.env.REACT_APP_LOGIN_URL;
    url = url.split("/");

    var protocol = url[0];
    var host = url[2];
    var origin = protocol + "//" + host;
    if (event.origin === origin) {
      SingleLogin(event.data);
    }
    return;
  };
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="non-auth-header">
        <h2 className="theme-font-inter-bold">
          {t("texts.welcome_to_black_force")}
        </h2>
        <span className="theme-font-inter-regular">
          {t("texts.welcome_detail")}
        </span>
      </div>
      <div className="form mb-4 box-width">
        {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
        <TextComponent
          name={"userName"}
          label={"username"}
          placeholder={"username"}
          value={values.userName}
          setValue={updateFormValues}
          classes={""}
          error={errors.userName}
          required={true}
        />
        <PasswordComponent
          name={"password"}
          label={"password"}
          placeholder={"password"}
          value={values.password}
          setValue={updateFormValues}
          classes={""}
          error={errors.password}
          required={true}
        />
        <ButtonComponent
          text="login"
          onClick={loginAction}
          disabled={loader && loaderType === "login"}
          loading={loader && loaderType === "login"}
          variant={"blue-1 mb-3"}
        />
        {isSSOEnabled && (
          <ButtonComponent
            text="login_via_single_login"
            onClick={loginSingleLoginAction}
            disabled={loader && loaderType === "single_login"}
            loading={loader && loaderType === "single_login"}
            variant={"blue-2"}
          />
        )}
      </div>
      <span className="theme-font-inter-regular register">
        {t("texts.do_not_have_account")}{" "}
        <span
          className="theme-font-inter-medium"
          onClick={() => {
            window.location.href =
              process.env.REACT_APP_REGISTER_LINK1 +
              "client_id=" +
              process.env.REACT_APP_REGISTER_LINK2 +
              "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=" +
              process.env.REACT_APP_WEBSITE_URL +
              "/register";
          }}
        >
          {t("texts.register")}
        </span>
      </span>
    </div>
  );
}

export default Login;
