import React from "react";
import PropTypes from "prop-types";

function DisplayHtml({ html }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
DisplayHtml.propTypes = {
  html: PropTypes.string.isRequired,
};
export default DisplayHtml;
