import React from "react";
import PropTypes from "prop-types";
import EditIcon from "assets/images/form/edit-icon.svg";
import DeactivateIcon from "assets/images/form/deactivate-icon.svg";
import ActivateIcon from "assets/images/form/activate-icon.png";
import DetailIcon from "assets/images/form/detail-view.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function CourseActions({ data, props }) {
  const userRole = useRecoilValue(getUserRole);
  const navigate = useNavigate();
  const editCourse = () => {
    if (data.is_active)
      navigate("/edit_course", { state: { update_id: data.id } });
    else props.actionOnDeactivatedCourse();
  };
  const viewCourseDetail = () => {
    if (data.is_active)
      navigate("/course_detail", { state: { record_id: data.id } });
    else props.actionOnDeactivatedCourse();
  };
  const viewCourseModuleUnitsList = () => {
    if (data.is_active)
      navigate("/course_modules", { state: { record_id: data.id } });
    else props.actionOnDeactivatedCourse();
  };
  const deleteCourse = async () => {
    props.deactivateCourseConfirmation(data.id, data.is_active);
  };

  return (
    <div className="d-flex gap-3 table-actions">
      {userRole === "SUPER_ADMIN" ? (
        <span onClick={viewCourseModuleUnitsList}>View Modules & Units</span>
      ) : userRole === "STUDENT" || userRole === "TEACHER" ? (
        <img onClick={viewCourseDetail} src={DetailIcon} alt="edit-icon" />
      ) : (
        userRole === "BUSINESS_ADMIN" && (
          <>
            <img onClick={viewCourseDetail} src={DetailIcon} alt="edit-icon" />
            <img onClick={editCourse} src={EditIcon} alt="edit-icon" />
            <img
              onClick={deleteCourse}
              src={data.is_active ? DeactivateIcon : ActivateIcon}
              alt="edit-icon"
            />
          </>
        )
      )}
    </div>
  );
}
CourseActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default CourseActions;
