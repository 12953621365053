import React from "react";
import PropTypes from "prop-types";

function LoaderComponent({ classes }) {
  return (
    <div className="loader-component">
      <div className={`spinner-border center ${classes}`}></div>
    </div>
  );
}
LoaderComponent.propTypes = {
  classes: PropTypes.string,
};
export default LoaderComponent;
