import * as yup from "yup";
export const BasicDetailsSchema = yup.object({
  title: yup.string().required("Title is required"),
  reward_credits: yup.string().required("Reward Credits is required"),
  enroll_credits: yup.string().required("Enrolled Credits is required"),
  detail: yup.string().required("Description is required"),
  category_id: yup.string().required("Select Category"),
  is_free: yup.string().required("Select Is Free"),
  author_name: yup.string().required("Author Name is required"),
  language: yup.string().required("Language is required"),
  payment_type: yup.string().required("Select Payment Type"),
  completion_duration: yup.string().required("Duration is required"),
  quiz_url: yup.string().required("Quiz Url is required"),
  short_description: yup.string().required("Short Description is required"),
});
export const TagsLearnHighlightsSchema = yup.object({
  tags: yup.array().required().min(1, "Add at least one tag"),
  learning_points: yup
    .array()
    .required()
    .min(1, "Add at least one learning point"),
});
export const NotesModulesSchema = yup.object({
  modules: yup.array().required().min(1, "Add at least one Module"),
  documents: yup.array().required().min(1, "Add at least one document/note"),
  urls: yup.array().required().min(1, "Add at least one url"),
});
export const CertificationSchema = yup.object({
  completed_percentage: yup.string().required("Completed Percentage is required"),
  quiz_percentage: yup.string().required("Quiz Percentage is required"),
  validity_period: yup.string().required("Validity Period is required"),
  validity_type: yup.string().required("Validity Type is required"),
  cert_description: yup.string().required("Certificate Description is required"),
});
