import React, { useEffect, useState } from "react";
import ENROLLMENT_DETAIL_IMG from "assets/images/enrollment/detail.png";
import ButtonComponent from "components/ButtonComponent";
import EnrollmentModulesUnitsList from "./EnrollmentModulesUnitsList";
import { useLocation, useNavigate } from "react-router-dom";

function EnrollmentUnitsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [enrollmentId, setEnrollmentId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [recordId, setRecordId] = useState("");
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.enrollment_id &&
      location.state.enrollment_id !== ""
    ) {
      setRecordId(location.state.id);
      setModuleId(location.state.module_id);
      setEnrollmentId(location.state.enrollment_id);
    }
  }, [location]);
  const openCourseNotes = () => {
    navigate("/enrollment_notes", {
      state: { record_id: recordId, record_type: "module" },
    });
  };
  return (
    <div className="enrollment-details">
      <div className="details">
        <img src={ENROLLMENT_DETAIL_IMG} alt={"Enrollment Detail"} />
        <div className="outline">
          <h2>Module Units List</h2>
          <p>
            Typically outlines the main topics or sections covered in a course.
            It serves as a guide to the course structure, detailing each module
            or unit's content, learning objectives, and any associated
            activities or assessments.
          </p>
          <ButtonComponent
            text="view_module_notes"
            variant="blue-1"
            loading={false}
            disabled={false}
            onClick={openCourseNotes}
          />
        </div>
      </div>
      <div className="modules">
        <div className="card course-admin-detail">
          <div className="detail m-0">
            <div className="learning-points-list bg-white py-9">
              {enrollmentId !== "" && (
                <EnrollmentModulesUnitsList
                  type="module"
                  module_id={moduleId}
                  enrollment_id={enrollmentId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EnrollmentUnitsList;
