import React, { useEffect, useState } from "react";
import ButtonComponent from "components/ButtonComponent";
import SelectComponent from "components/SelectComponent";
import TextComponent from "components/TextComponent";
import TextRadioComponent from "components/TextRadioComponent";
import { CallAPI } from "actions/General";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function AuthSettings() {
  const userRole = useRecoilValue(getUserRole)
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [isDetailFetched, setIsDetailFetched] = useState(false);
  const [fetchedDetails, setFetchedDetails] = useState({
    roles: [],
    profiles: [],
    departments: [],
  });
  const [apiError, setApiError] = useState("");
  const [recordId, setRecordId] = useState("");
  const [values, setValues] = useState({});
  const updateSettings = async () => {
    setLoadingType("save");
    await CallAPI(
      "SAVE_DEFAULT_SYSTEM_SETTINGS",
      {
        ...values,
        default_business: parseInt(values.default_business),
        default_role: parseInt(values.default_role),
        default_profile: parseInt(values.default_profile),
        default_department: parseInt(values.default_department),
      },
      recordId,
      null,
      null,
      null
    );
  };
  const getBusinessDetails = async (data = null) => {
    setLoadingType("fetch_details");
    const result = await CallAPI(
      "GET_OWNER_BUSINESS_DETAILS",
      {
        default_business: parseInt(data.default_business ? data.default_business :values.default_business),
        public_key: data.public_key ? data.public_key : values.public_key,
        private_key: data.private_key ? data.private_key : values.private_key,
      },
      null,
      setLoading,
      setApiError,
      null
    );
    if (result.status) {
      const roles = result.data.roles;
      const profiles = result.data.profiles;
      const departments = result.data.departments;
      let rolesList = roles.map((singleRole) => {
        return { value: singleRole.id, displayValue: singleRole.name };
      });
      let profilesList = profiles.map((singleProfile) => {
        return { value: singleProfile.id, displayValue: singleProfile.name };
      });
      let departmentsList = departments.map((singleDepartment) => {
        return {
          value: singleDepartment.id,
          displayValue: singleDepartment.name,
        };
      });
      setFetchedDetails({
        roles: rolesList,
        profiles: profilesList,
        departments: departmentsList,
      });
      setIsDetailFetched(true);
    }
  };
  const updateFormValues = (name, value) => {
    if (
      name === "default_business" ||
      name === "public_key" ||
      name === "private_key"
    ) {
      setIsDetailFetched(false);
    }
    setValues({ ...values, [name]: value });
  };
  const getDefaultSettings = async () => {
    const result = await CallAPI(
      "GET_DEFAULT_SYSTEM_SETTINGS",
      null,
      null,
      null,
      null,
      null
    );
    if (result.status) {
      setRecordId(result.data.id);
      setValues({
        sso_enabled: result.data.sso_enabled,
        default_business: result.data.default_business,
        public_key: result.data.public_key,
        private_key: result.data.private_key,
        default_role: result.data.default_role,
        default_profile: result.data.default_profile,
        default_department: result.data.default_department,
        subscription_management_email:
          result.data.subscription_management_email,
      });
      if (result.data.private_key) getBusinessDetails(result.data);
    }
  };
  useEffect(() => {
    console.log(userRole)
    getDefaultSettings();
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-3 page-header">
        <span>Auth Settings</span>
        <div>
          <ButtonComponent
            text="update_settings"
            onClick={updateSettings}
            variant="blue-1"
            loading={loading && loadingType === "save"}
            disabled={loading && loadingType === "save"}
          />
        </div>
      </div>
      <div className="p-5 gap-5 box-width">
        <div className="d-flex gap-4">
          <TextRadioComponent
            name={"sso_enabled"}
            label={"sso_enable"}
            placeholder={"enable_disable_sso"}
            value={values.sso_enabled}
            setValue={updateFormValues}
            classes={{}}
          />
        </div>
        <div className="d-flex gap-4">
          <TextComponent
            name={"default_business"}
            label={"default_business"}
            placeholder={"default_business"}
            value={values.default_business}
            setValue={updateFormValues}
            classes={{}}
            type="number"
          />
        </div>
        <div className="d-flex gap-4">
          <TextComponent
            name={"public_key"}
            label={"public_key"}
            placeholder={"public_key"}
            value={values.public_key}
            setValue={updateFormValues}
            classes={{}}
          />
        </div>
        <div className="d-flex gap-4">
          <TextComponent
            name={"private_key"}
            label={"private_key"}
            placeholder={"private_key"}
            value={values.private_key}
            setValue={updateFormValues}
            classes={{}}
          />
        </div>
        {isDetailFetched && (
          <>
            <div className="d-flex gap-4">
              <SelectComponent
                name={"default_profile"}
                label={"default_profile"}
                value={values.default_profile}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.profiles}
              />
            </div>

            <div className="d-flex gap-4">
              <SelectComponent
                name={"default_department"}
                label={"default_department"}
                value={values.default_department}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.departments}
              />
            </div>
            <div className="d-flex gap-4">
              <SelectComponent
                name={"default_role"}
                label={"default_role"}
                value={values.default_role}
                onChange={updateFormValues}
                classes={{}}
                optionsList={fetchedDetails.roles}
              />
            </div>
            <div className="d-flex gap-4">
              <TextComponent
                name={"subscription_management_email"}
                label={"subscription_email"}
                placeholder={"subscription_email"}
                value={values.subscription_management_email}
                setValue={updateFormValues}
                classes={{}}
              />
            </div>
          </>
        )}
      </div>
      {!isDetailFetched && (
        <div className="text-center">
          <ButtonComponent
            text="fetch_details"
            onClick={getBusinessDetails}
            variant="blue-1"
            loading={loading && loadingType === "fetch_details"}
            disabled={loading && loadingType === "fetch_details"}
          />
        </div>
      )}
    </div>
  );
}

export default AuthSettings;
