export const AddProgramTabs = [
  {
    displayValue: "basic_details",
  },
  {
    displayValue: "tags_highlights",
  },
  {
    displayValue: "courses",
  },
  {
    displayValue: "certification",
  },
  {
    displayValue: "preview",
  },
];
export const ViewProgramDetailTabs = [
  {
    displayValue: "detail_view_description",
  },
  {
    displayValue: "detail_view_learn_section",
  },
  {
    displayValue: "detail_view_courses",
  },
  // {
  //   displayValue: "detail_view_enrollments",
  // },
];
export const ProgramTableHeader = [
  { key: "slug", displayValue: "code", sort: true },
  { key: "title", displayValue: "title", sort: true },
  {
    key: "is_free",
    displayValue: "is_free",
    sort: true,
    callbackFunction: "displayAutoCharged",
  },
  { key: "enroll_credits", displayValue: "enroll_credits", sort: true },
  { key: "reward_credits", displayValue: "reward_credits", sort: true },
  { key: "language", displayValue: "language", sort: true },
  {
    key: "tags",
    displayValue: "tags",
    sort: true,
    callbackFunction: "displayTags",
  },
  {
    key: "status",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayUnitStatus",
  },
];
export const ProgramGridHeader = {
  header: [
    { callbackFunction: "displayTitleWithSlug" },
    { callbackFunction: "displayIsFreeField" },
  ],
  body: [
    {
      key: "enroll_credits",
      displayValue: "enroll_credits",
    },
    {
      key: "reward_credits",
      displayValue: "reward_credits",
    },
    {
      key: "language",
      displayValue: "language",
    },
    {
      key: "tags",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
    {
      key: "status",
      displayValue: "status",
      callbackFunction: "displayUnitStatus",
    },
  ],
};
export const ModalButtons = {
  coursesConflict: {
    text: "courses_conflict",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "coursesErrorModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "deactivate",
        variant: "red-1",
        onClick: "deactivateProgram",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmActive: {
    text: "active_record",
    buttons: [
      {
        text: "activate",
        variant: "blue-1",
        onClick: "deactivateProgram",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordUpdate: {
    text: "record_update",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordCreated: {
    text: "record_created",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordActivated: {
    text: "record_activate",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  deactivateActionError: {
    text: "action_on_deactivated_error",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
