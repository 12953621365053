import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize } from "config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ModalButtons,
  EnrollmentsTableHeader,
  EnrollmentsGridHeader,
} from "./utils";
import GridView from "components/GridView";
import CustomModal from "components/CustomModal";

function Enrollments() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [enrollments, setEnrollments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const getEnrollmentsList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_ENROLLMENTS_LIST",
      { relations: [], page, pageSize: limit },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setEnrollments(result.data.enrollments);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };
  useEffect(() => {
    getEnrollmentsList();
  }, [page, limit]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deactivateEnrollmentConfirmation = async (id) => {
    setDeactivatedId(id);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType("confirmDelete");
  };

  const deactivateEnrollment = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "CANCEL_ENROLLMENT",
      {},
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    console.log("result", result);
    if (result.status) {
      getEnrollmentsList();
      setModalVariant("success");
      setModalType("recordDelete");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"enrollments_listing"}
          setDisplayType={()=>{}}
          displayType={""}
          addItem={""}
        />
        <div className={"card-body py-4"}>
          {displayType === "list" && (
            <TableComponent
              data={enrollments}
              header={EnrollmentsTableHeader}
              type="enrollments"
              props={{
                deactivateEnrollmentConfirmation,
              }}
            />
          )}
        </div>
      </div>
      {displayType === "grid" && (
        <GridView data={enrollments} columns={EnrollmentsGridHeader} />
      )}
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deactivateEnrollment }}
        />
      )}
    </>
  );
}

export default Enrollments;
