import { atom, selector } from "recoil";
import { persistAtom } from "config/config";

export const authState = atom({
  key: "authState",
  default: {
    authToken: "",
    email: "",
    id: "",
    role: "",
    businessesList: [],
    details: {},
  },
  effects_UNSTABLE: [persistAtom],
});
export const isUserLoggedIn = selector({
  key: "isUserLoggedIn",
  get: ({ get }) => {
    const state = get(authState);
    return state.authToken !== "" ? true : false;
  },
});
export const isUserHasCognitoToken = selector({
  key: "isUserHasCognitoToken",
  get: ({ get }) => {
    const state = get(authState);
    return state.cognitoToken !== "" ? true : false;
  },
});
export const isUserHasRole = selector({
  key: "isUserHasRole",
  get: ({ get }) => {
    const state = get(authState);
    return state.role !== "" ? true : false;
  },
});
export const getUserRole = selector({
  key: "getUserRole",
  get: ({ get }) => {
    const state = get(authState);
    return state.role;
  },
});
export const getUserBusinessesList = selector({
  key: "getUserBusinessesList",
  get: ({ get }) => {
    const state = get(authState);
    return state.businessesList ? state.businessesList : [];
  },
});
export const getCognitoToken = selector({
  key: "getCognitoToken",
  get: ({ get }) => {
    const state = get(authState);
    return state.authToken ? state.authToken : "";
  },
});
