import { atom, selector } from "recoil";
import { persistAtom } from "config/config";

export const generalState = atom({
  key: "generalState",
  default: { sidebar_opened: true, sso_enabled: false, breadcrumb_type: "" },
  effects_UNSTABLE: [persistAtom],
});

export const isSideBarOpened = selector({
  key: "isSideBarOpened",
  get: ({ get }) => {
    const state = get(generalState);
    return state.sidebar_opened;
  },
});
export const getBreadcrumbType = selector({
  key: "getBreadcrumbType",
  get: ({ get }) => {
    const state = get(generalState);
    return state.breadcrumb_type;
  },
});
