import CustomModal from "components/CustomModal";
import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  const redirectToDashboard = () => {
    console.log("in")
    // navigate("/dashboard");
    navigate("/program");
  };
  return (
    <>
      <CustomModal
        variant="error"
        text="page_not_found"
        header=""
        onClose={redirectToDashboard}
        buttons={[
          {
            text: "ok",
            variant: "blue-1",
            onClick: "redirectToDashboard",
            loading: false,
            disabled: false,
          },
        ]}
        functionsList={{redirectToDashboard}}
      />
    </>
  );
}

export default ErrorPage;
