import * as yup from "yup";
export const SuperAdminLoginSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter valid Email"),
  password: yup.string().required("Password is required"),
});
export const UserLoginSchema = yup.object({
  userName: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});
