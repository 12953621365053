import React, { useEffect, useState } from "react";
import SearchImage from "assets/images/top_bar/search_image.svg";
import DownArrow from "assets/images/general/payment-arrow.svg";
import RightArrow from "assets/images/general_old/down-arrow-full.svg";
import DefaultUser from "assets/images/general_old/user-avatar.png";
import ButtonComponent from "./ButtonComponent";
import { useRecoilValue } from "recoil";
import { getBreadcrumbType } from "recoil/General";
import ProfileMenu from "./ProfileMenu";
import { Link, useNavigate } from "react-router-dom";
import { BreadCrumbsListing } from "config/BreadCrumbsListing";
import { authState } from "recoil/Auth";
import { getImagePreviewUrl } from "config/config";
import DisplaySVG from "./DisplaySVG";
import ThemeSwitcher from "views/ThemeSwitcher";

function TopBar() {
  const navigate = useNavigate();
  const [displayProfileMenu, setDisplayProfileMenu] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoader] = useState(false);

  const typeOfBreadCrumb = useRecoilValue(getBreadcrumbType);
  const userDetail = useRecoilValue(authState);

  const switchUser = () => {};

  const moveTo = (link) => {
    navigate(link);
  };

  const getImageUrl = async () => {
    if (userDetail.details.avatar) {
      let url = await getImagePreviewUrl(userDetail.details.avatar, setLoader);
      if (url) setImageUrl(url);
    }
  };
  useEffect(() => {
    getImageUrl();
  }, [userDetail?.details?.avatar]);
  return (
    <div id="kt_header" className={"header align-items-stretch"}>
      <div
        className={
          "container-fluid d-flex align-items-stretch justify-content-between"
        }
      >
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <div className="d-flex align-items-stretch" id="kt_header_nav">
            <div className="toolbar duplicate" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className={"container-fluid d-flex flex-stack"}
              >
                <div
                  id="kt_page_title"
                  data-kt-swapper="true"
                  data-kt-swapper-mode="prepend"
                  data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                  className={
                    "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                  }
                >
                  <h1
                    className="d-flex align-items-center my-1 text-hover-blue-1 cursor-pointer"
                    onClick={() =>
                      moveTo(BreadCrumbsListing[typeOfBreadCrumb].url)
                    }
                  >
                    {BreadCrumbsListing[typeOfBreadCrumb]
                      ? BreadCrumbsListing[typeOfBreadCrumb].breadcrumbs[0]
                      : ""}
                  </h1>
                  <span className="bullet bg-gray-400 w-5px h-2px mx-2"></span>
                  <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 mt-1">
                    {BreadCrumbsListing[typeOfBreadCrumb] &&
                      BreadCrumbsListing[typeOfBreadCrumb].breadcrumbs.map(
                        (item, index) =>
                          index !== 0 && (
                            <li className={""} key={index}>
                              {item}
                            </li>
                          )
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-stretch flex-shrink-0">
            {/* <div className={"d-flex align-items-center ms-1 ms-lg-3"}>
              <ThemeSwitcher />
            </div> */}
            <div
              className={"d-flex align-items-center ms-1 ms-lg-3"}
              id="kt_header_user_menu_toggle"
            >
              <div
                className={"cursor-pointer symbol symbol-30px symbol-md-40px"}
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
                onClick={() => setDisplayProfileMenu(!displayProfileMenu)}
              >
                <img
                  src={
                    !loader && userDetail?.details?.avatar
                      ? imageUrl
                      : DefaultUser
                  }
                  alt="metronic"
                />
                <span className="ms-2 theme-font-inter-regular">
                  Hi{" "}
                  {userDetail.role === "SUPER_ADMIN"
                    ? "Super Admin"
                    : userDetail.details.name}
                </span>
                <img className="rotate-180 profile-open" src={DownArrow} alt="down" />
              </div>

              {displayProfileMenu && (
                <ProfileMenu setDisplayProfileMenu={setDisplayProfileMenu} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;

export const Toolbar = () => {
  const typeOfBreadCrumb = useRecoilValue(getBreadcrumbType);

  return (
    <>
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className={"container-fluid d-flex flex-stack"}
        >
          <div
            id="kt_page_title"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className={
              "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            }
          >
            <h1 className="d-flex align-items-center my-1">
              {BreadCrumbsListing[typeOfBreadCrumb]
                ? BreadCrumbsListing[typeOfBreadCrumb].component
                : ""}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
