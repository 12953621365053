import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseImage from "assets/images/general_old/close.svg";
import TextComponent from "components/TextComponent";
import { CallAPI } from "actions/General";
import TextAreaComponent from "components/TextAreaComponent";
import TextRadioComponent from "components/TextRadioComponent";
import ButtonComponent from "components/ButtonComponent";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";

function EditSubscription({ editId, setEditId, getSubscriptionsList }) {
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [values, setValues] = useState({
    title: "",
    price: "",
    credits: "",
    credits_expires: "",
    auto_charged: false,
    is_cpd: false,
    detail: "",
  });
  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const getSubscriptionDetails = async () => {
    const result = await CallAPI(
      "GET_SUBSCRIPTION_PLAN_DETAIL",
      null,
      editId,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      const subscriptionItem = result.data;
      setValues({
        title: subscriptionItem.title,
        price: parseInt(subscriptionItem.price),
        credits: parseInt(subscriptionItem.credits),
        credits_expires: parseInt(subscriptionItem.credits_expires),
        auto_charged: subscriptionItem.auto_charged,
        detail: subscriptionItem.detail,
        is_cpd: subscriptionItem.is_cpd,
      });
    }
  };
  useEffect(() => {
    getSubscriptionDetails();
  }, [editId]);

  const updateSubscription = async () => {
    setLoaderType("save");
    console.log("values", values);
    let result = await CallAPI(
      "UPDATE_SUBSCRIPTION_PLAN_DETAIL",
      {
        title: values.title,
        price: parseInt(values.price),
        credits: parseInt(values.credits),
        credits_expires: parseInt(values.credits_expires),
        auto_charged: values.auto_charged,
        detail: values.detail,
        is_cpd: values.is_cpd,
      },
      editId,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setEditId("");
      getSubscriptionsList();
    }
  };
  return (
    <div className={`custom-modal `}>
      {loader && loaderType === "load_detail" && <LoaderComponent classes="" />}
      {apiError && <ErrorComponent text={apiError} variant="danger" />}
      <div className="detail subscriptions">
        <img
          className="close"
          src={CloseImage}
          onClick={() => setEditId("")}
          alt="close"
        />
        <div className="d-flex flex-column gap-3">
          <TextComponent
            classes={""}
            name={"title"}
            value={values.title}
            setValue={updateFormValues}
            error={""}
            label={"title"}
            placeholder={"title"}
            disabled={true}
            required={true}
          />
          <TextComponent
            classes={""}
            name={"price"}
            value={values.price}
            setValue={updateFormValues}
            error={""}
            label={"price"}
            placeholder={"price"}
            type="number"
            required={true}
          />
          <TextComponent
            classes={""}
            name={"credits"}
            value={values.credits}
            setValue={updateFormValues}
            error={""}
            label={"credits"}
            placeholder={"credits"}
            type="number"
            required={true}
          />
          <TextComponent
            classes={""}
            name={"credits_expires"}
            value={values.credits_expires}
            setValue={updateFormValues}
            error={""}
            label={"credits_expire"}
            placeholder={"credits_expire"}
            overLayText={"months"}
            type="number"
            required={true}
          />
          <TextRadioComponent
            name={"auto_charged"}
            label={"auto_charged"}
            placeholder={"auto_charged"}
            value={values.auto_charged}
            setValue={updateFormValues}
            classes={""}
            required={true}
          />
          <div>
            <TextAreaComponent
              classes={"w-100"}
              name={"detail"}
              value={values.detail}
              setValue={updateFormValues}
              error={""}
              label={"detail"}
              placeholder={"detail"}
              required={true}
            />
          </div>
          <div className="ms-auto">
            <ButtonComponent
              text="update"
              onClick={updateSubscription}
              variant="blue-1"
              loading={loader && loaderType === "save"}
              disabled={loader && loaderType === "save"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
EditSubscription.propTypes = {
  editId: PropTypes.string.isRequired,
  setEditId: PropTypes.func.isRequired,
  getSubscriptionsList: PropTypes.func.isRequired,
};
export default EditSubscription;
