import SideBar from "components/SideBar";
import TopBar, { Toolbar } from "components/TopBar";
import { sideBarMenuItemsList } from "config/MenuItemsList";
import React, { Fragment, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getUserRole, isUserHasRole, isUserLoggedIn } from "recoil/Auth";
import { generalState, isSideBarOpened } from "recoil/General";

const AuthenticatedLayout = () => {
  const location = useLocation();
  const isLogin = useRecoilValue(isUserLoggedIn);
  const userRole = useRecoilValue(getUserRole);
  const userHasRole = useRecoilValue(isUserHasRole);
  const isSidebarOpened = useRecoilValue(isSideBarOpened);
  const [commonSetting, setCommonSettings] = useRecoilState(generalState);
  const [isUserAllowedToRoute, setIsUserAllowedToRoute] = useState("");
  useEffect(() => {
    let isAllowedRoute = "not_allowed";
    const currentPath = location.pathname.substring(1);
    sideBarMenuItemsList.map((singleRoute) => {
      if (singleRoute.type.indexOf(currentPath) > -1) {
        if (
          !singleRoute.allowedFor ||
          singleRoute.allowedFor.indexOf(userRole) > -1
        )
          isAllowedRoute = "allowed";
      }
    });
    setIsUserAllowedToRoute(isAllowedRoute);
  }, [location.pathname]);
  useEffect(() => {
    let breadcrumb_type = location.pathname.substring(
      1,
      location.pathname.length
    );
    if (breadcrumb_type !== commonSetting.breadcrumb_type)
      setCommonSettings({
        ...commonSetting,
        breadcrumb_type: breadcrumb_type,
      });
  }, [location.pathname, commonSetting, setCommonSettings]);

  return (
    <Fragment>
      {isUserAllowedToRoute === "allowed" ? (
        <div className={` page d-flex flex-row flex-column-fluid`}>
          {isLogin && userHasRole ? (
            <>
              <SideBar />
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <TopBar />

                <div
                  id="kt_content"
                  className="content d-flex flex-column flex-column-fluid"
                >
                  <Toolbar />
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className={"container"}>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : isLogin ? (
            <Navigate
              to="/choose_business"
              replace
              state={{ from: location }}
            />
          ) : (
            <Navigate to="/login" replace state={{ from: location }} />
          )}
        </div>
      ) : isUserAllowedToRoute === "not_allowed" ? (
        <Navigate to="/error" replace state={{ from: location }} />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default AuthenticatedLayout;
