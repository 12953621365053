export const SeasonsTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  {
    key: "start_date",
    displayValue: "season_start_date",
    sort: true,
    callbackFunction: "displaySeasonDate",
  },
  {
    key: "end_date",
    displayValue: "season_end_date",
    sort: true,
    callbackFunction: "displaySeasonDate",
  },
];
