import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import TextAreaComponent from "components/TextAreaComponent";
import TextComponent from "components/TextComponent";

function BasicDetails({ values, errors, updateFormValues, otherDetails }) {
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <TextComponent
            name={"title"}
            label={"enter_title"}
            placeholder={"title"}
            value={values.title}
            setValue={updateFormValues}
            classes={""}
            error={errors["title"] ?? ""}
            required={true}
          />
          <SelectComponent
            name={"teacher"}
            label={"teacher"}
            value={values.teacher}
            error={errors["teacher"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.users}
            required={true}
          />
          <SelectComponent
            name={"auto_generate_link"}
            label={"auto_generate_link"}
            value={values.auto_generate_link}
            error={errors["auto_generate_link"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.auto_generate_link}
            required={true}
          />
          {values.auto_generate_link === "0" && (
            <TextComponent
              name={"link"}
              label={"zoom_link"}
              placeholder={"zoom_link"}
              value={values.link}
              setValue={updateFormValues}
              classes={""}
              error={errors["link"] ?? ""}
              required={true}
            />
          )}
          <SelectComponent
            name={"auto_upload_session"}
            label={"select_auto_upload_session"}
            value={values.auto_upload_session}
            error={errors["auto_upload_session"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.auto_upload_session}
            required={false}
          />
          <SelectComponent
            name={"type"}
            label={"select_type"}
            value={values.type}
            error={errors["type"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.type}
            required={true}
          />
          {values.type === "PROGRAM" && (
            <SelectComponent
              name={"program_id"}
              label={"select_program"}
              value={values.program_id}
              error={errors["program_id"] ?? ""}
              classes={""}
              onChange={updateFormValues}
              optionsList={otherDetails.programs}
              required={true}
            />
          )}
          {values.type === "COURSE" && (
            <SelectComponent
              name={"course_id"}
              label={"select_course"}
              value={values.course_id}
              error={errors["course_id"] ?? ""}
              classes={""}
              onChange={updateFormValues}
              optionsList={otherDetails.courses}
              required={true}
            />
          )}
        </div>
        <div>
          <TextAreaComponent
            name={"description"}
            label={"description"}
            placeholder={"description"}
            value={values.description}
            error={errors["description"] ?? ""}
            setValue={updateFormValues}
            classes={"w-100"}
            required={true}
          />
        </div>
      </div>
    </>
  );
}
BasicDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default BasicDetails;
