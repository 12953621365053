import React, { useEffect, useState } from "react";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import TableComponent from "components/TableComponent";
import { SeasonsTableHeader } from "./utils";
import EditSeason from "./EditSeason";

function Seasons() {
  const [editObj, setEditObj] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const getSeasonsList = async () => {
    const APIName = "GET_ALL_SEASONS";
    const APIBody = null;
    setLoaderType("get_listing");
    const result = await CallAPI(
      APIName,
      APIBody,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      let seasonObj = {};
      result.data.map((singleSeason) => {
        seasonObj[singleSeason.title] = {
          start: singleSeason.start_date * 1000,
          end: singleSeason.end_date * 1000,
        };
      });
      result.data.map((singleSeason) => {
        singleSeason.allSeasonDates = seasonObj;
      });
      setSeasons(result.data);
    }
  };
  useEffect(() => {
    getSeasonsList();
  }, []);
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"seasons_listing"}
          setDisplayType={() => {}}
          displayType={""}
          addItem={""}
        />
        <div className={"card-body py-4"}>
          <TableComponent
            data={seasons}
            header={SeasonsTableHeader}
            type="seasons"
            props={{ setEditObj }}
          />
        </div>
      </div>
      {editObj && editObj.id && (
        <EditSeason
          editObj={editObj}
          setEditObj={setEditObj}
          getSeasonsList={getSeasonsList}
        />
      )}
    </>
  );
}

export default Seasons;
