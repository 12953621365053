import React, { useEffect, useState } from "react";
import { CategoriesTableHeader, ModalButtons } from "./utils";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import TableComponent from "components/TableComponent";
import { useTranslation } from "react-i18next";
import AddCategory from "./AddCategory";
import { DefaultPaginationSize } from "config/config";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function Categories() {
  const { t } = useTranslation();
  const userRole = useRecoilValue(getUserRole);
  const [hasMoreCategories, setHasMoreCategories] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [lastRecordId, setLastRecordId] = useState("");
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(DefaultPaginationSize);
  const [editObj, setEditObj] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deletedId, setDeletedId] = useState("");

  const getAllCategories = async (type = "forward", recordId = null) => {
    setLoaderType("list_all");
    let result = await CallAPI(
      "FETCH_ALL_CATEGORIES",
      {
        page: page,
        pageSize: limit,
        type,
        lastRecordId: recordId !== null ? recordId : lastRecordId,
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      let categoriesList = result.data.categories;
      if (recordId === "" || type === "backward") {
        setCategories(categoriesList);
        setLastRecordId(
          categoriesList.length > 0
            ? categoriesList[categoriesList.length - 1].id
            : ""
        );
      } else if (categoriesList.length > 0) {
        setCategories([...categories, ...categoriesList]);
        setLastRecordId(categoriesList[categoriesList.length - 1].id);
        if (categoriesList.length < limit && type !== "backward") {
          setHasMoreCategories(false);
        }
      } else {
        setHasMoreCategories(false);
      }
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
      setHasMoreCategories(false);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, [page]);

  const categoryDeleted = (id) => {
    let recordId = null;
    if (id === lastRecordId && categories.length > 1) {
      recordId = categories[categories.length - 2].id;
    }
    if (categories.length > 1) getAllCategories("backward", recordId);
    else getAllCategories("forward", "");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteCategoryConfirmation = async (id) => {
    setDeletedId(id);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType("confirmDelete");
  };

  const deleteCategory = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DELETE_CATEGORY",
      null,
      deletedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      if (editObj && deletedId === editObj.id) setEditObj(null);
      categoryDeleted(deletedId);
      setModalVariant("success");
      setModalType("recordDelete");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  return (
    <>
      {loader && loaderType === "list_all" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      {userRole === "BUSINESS_ADMIN" && (
        <div className="card mb-4">
          <div className={"card-body py-3"}>
            <div className="mb-3 pt-4">
              <AddCategory
                getAllCategories={getAllCategories}
                setEditObj={setEditObj}
                editObj={editObj}
              />
            </div>
          </div>
        </div>
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <div className={"card-body py-4"}>
          <TableComponent
            data={categories}
            header={CategoriesTableHeader}
            type="categories"
            props={{ setEditObj, deleteCategoryConfirmation }}
          />
          {hasMoreCategories && (
            <span
              onClick={() => {
                setPage(page + 1);
              }}
              className="load-more"
            >
              {t("general.load_more")}
            </span>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deleteCategory }}
        />
      )}
    </>
  );
}

export default Categories;
