import { CallAPI } from "actions/General";
import ButtonComponent from "components/ButtonComponent";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import SingleFileDropZoneComponent from "components/SingleFileDropZoneComponent";
import TextAreaSimpleComponent from "components/TextAreaSimpleComponent";
import TextComponent from "components/TextComponent";
import React, { useState } from "react";
import { ProfileSchema } from "./FieldValidationRoles";
import { useRecoilState } from "recoil";
import { authState } from "recoil/Auth";
import { UploadMedia } from "config/config";
import { useTranslation } from "react-i18next";

function UpdateDetails() {
  const { t } = useTranslation();
  const [authStateDetail, setAuthStateDetails] = useRecoilState(authState);
  const errorInitialState = { title: "", about: "", profile_pic: "" };
  const [values, setValues] = useState({
    title: authStateDetail.details.job_title,
    about: authStateDetail.details.about,
    profile_pic: authStateDetail.details.avatar
      ? { id: authStateDetail.details.avatar }
      : null,
  });
  const [errors, setErrors] = useState(errorInitialState);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const saveProfile = async () => {
    try {
      setErrors({});
      await ProfileSchema.validate(values, { abortEarly: false });

      console.log("typeof", values.profile_pic);
      let profilePicId = null;
      if (values.profile_pic && values.profile_pic.id === undefined) {
        profilePicId = await UploadMedia(
          [values.profile_pic],
          [
            {
              name: values.profile_pic.path,
              size: values.profile_pic.size.toString(),
              extension: values.profile_pic.type,
            },
          ]
        );
        profilePicId = profilePicId[0];
      }
      console.log(profilePicId);

      let profileObject = {
        job_title: values.title,
        about: values.about,
        avatar:
          values.profile_pic && values.profile_pic.id === undefined
            ? profilePicId
            : values.profile_pic && values.profile_pic.id
            ? values.profile_pic.id
            : null,
      };
      let result = await CallAPI(
        "UPDATE_USER_PROFILE",
        profileObject,
        null,
        setLoading,
        setApiError,
        null
      );
      if (result.status) {
        console.log(result);
        let authState = { ...authStateDetail };
        authState.details = {
          ...authState.details,
          ...profileObject,
        };
        setAuthStateDetails(authState);
      }
    } catch (error) {
      console.log("error", error);
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };
  return (
    <>
      {loading && <LoaderComponent classes={"mx-4"} />}
      <div className="p-4 mx-3 d-flex gap-4 flex-column">
        {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
        <div className="detail-single-item">
          <span>{t("fields.profile_pic")}</span>
          <div>
            <SingleFileDropZoneComponent
              value={values.profile_pic}
              setValue={updateFormValues}
              error=""
              label=""
              name="profile_pic"
              classes={{ previewClass: "" }}
              uploadText={"Upload Picture"}
            />
          </div>
        </div>
        <div className="detail-single-item">
          <span>{t("fields.job_title")}</span>
          <div>
            <TextComponent
              name="title"
              value={values.title}
              setValue={updateFormValues}
              error={errors.title}
              label=""
              placeholder="job_title"
              classes={{}}
            />
          </div>
        </div>
        <div className="detail-single-item">
          <span>{t("fields.about")}</span>
          <div>
            <TextAreaSimpleComponent
              name="about"
              value={values.about}
              setValue={updateFormValues}
              error={errors.about}
              label=""
              placeholder="about"
              classes={{}}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end w-auto">
          <div>
            <ButtonComponent
              variant="blue-1"
              loading={loading}
              disabled={loading}
              text="save_changes"
              onClick={saveProfile}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateDetails;
