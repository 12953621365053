import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function SelectWithTextComponent({
  name,
  name1,
  label,
  placeholder,
  disabled,
  classes,
  optionsList,
  value,
  value1,
  setValue,
  error,
  type,
  required,
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row ${classes ? classes : "mb-5"}`}>
      {label && (
        <label className={`form-label`}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <div className="item">
        <input
          type={type}
          className={"form-control form-control-lg form-control-solid"}
          placeholder={t("fields." + placeholder)}
          value={value}
          name={name}
          disabled={disabled}
          onChange={(e) => setValue(name, e.target.value)}
        />
        <div className="divider"></div>
        <select
          className={"form-control form-control-lg form-control-solid"}
          value={value1}
          name={name1}
          onChange={(e) => setValue(name1, e.target.value)}
        >
          {optionsList.map((singleOption, index) => {
            return (
              <option key={index} value={singleOption.value}>
                {singleOption.displayValue}
              </option>
            );
          })}
        </select>
      </div>
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}
SelectWithTextComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  name1: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  optionsList: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value1: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default SelectWithTextComponent;
