import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DisplaySVG from "./DisplaySVG";

function ButtonComponent({
  text,
  onClick,
  disabled,
  variant,
  className,
  loading,
  langVal = "",
  langKey = "",
  icon,
}) {
  const { t } = useTranslation();
  return (
    <button onClick={onClick} disabled={disabled} className={`btn ${"variant-"+variant}`}>
      {icon && <DisplaySVG path={icon} svgClassName="svg-icon-2" />}
      {t("button." + text, { [langKey]: langVal })}
      {loading && (
        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
      )}
    </button>
  );
}

ButtonComponent.propTypes = {
  langVal: PropTypes.number,
  langKey: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.string,
};

export default ButtonComponent;
