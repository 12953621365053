import React from "react";
import PropTypes from "prop-types";
import ItemListingComponent from "components/ItemListingComponent";
import SelectionComponent from "components/SelectionComponent";

function NotesModules({ values, errors, updateFormValues }) {
  return (
    <div className="d-flex flex-column gap-4 w-100">
      <SelectionComponent
        type={"modules"}
        items={values.modules}
        updateItems={updateFormValues}
        error={errors["modules"] ?? ""}
        allowSorting={true}
      />
      <ItemListingComponent
        type={"documents"}
        items={values.documents}
        updateItems={updateFormValues}
        error={errors["documents"] ?? ""}
      />
      <ItemListingComponent
        type={"urls"}
        items={values.urls}
        updateItems={updateFormValues}
        error={errors["urls"] ?? ""}
      />
    </div>
  );
}
NotesModules.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default NotesModules;
