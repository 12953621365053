import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import DropZoneAddMultiple from "assets/images/general_old/dropzone_add_multiple.svg";
import ButtonComponent from "./ButtonComponent";
import DocumentImage from "assets/images/general_old/document-solid.svg";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/images/general_old/close.svg";

function MultipleFileDropZoneComponent({
  name,
  value,
  label,
  classes,
  setValue,
  error,
  type,
  required,
}) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [isFilesUpdated, setIsFilesUpdated] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {},

    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setIsFilesUpdated(true);
    },
  });

  useEffect(() => {
    if (files.length > 0 && isFilesUpdated) {
      setValue(name, [...files, ...value]);
      setIsFilesUpdated(false);
    }
  }, [files, name, setValue, setIsFilesUpdated, isFilesUpdated]);

  const removeItemFromList = (index) => {
    value.splice(index, 1);
    setValue(name, value);
    console.log("value", value);
  };
  return (
    <div className={`drop-zone-component multiple fv-row mb-5 w-100 ${classes}`}>
      <label className={`form-label`}>
        <span className={`${required ? "required" : ""}`}>
          {label ? t("fields." + label) : ""}
        </span>
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} multiple={false} />

        <img src={DropZoneAddMultiple} alt="DropZoneAdd" />
        <div>
          <ButtonComponent
            text="choose_file_to_upload"
            variant="blue-1 px-5"
            loading={false}
            disabled={false}
            onClick={() => {}}
          />
        </div>
        <span className="multiple">{t("texts.drag_drop_here")}</span>
      </div>

      <div className="d-flex gap-3 flex-wrap view">
        {type === "image" ? (
          <>
            {files
              ? files.map((singleFile, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(singleFile)}
                    alt="single logo"
                  />
                ))
              : null}
            {value
              ? value.map(
                  (singleFile, index) =>
                    singleFile &&
                    singleFile.id && (
                      <img key={index} src={singleFile} alt="multiple logo" />
                    )
                )
              : null}
          </>
        ) : (
          <>
            {/* {files
              ? files.map((singleFile, index) => (
                  <div className="detail" key={index}>
                    <img src={DocumentImage} alt="detail icon" />
                    <div>
                      <span className="title">{singleFile.name}</span>
                      <span className="size">{singleFile.size}</span>
                    </div>
                  </div>
                ))
              : null} */}
            {value
              ? value.map(
                  (singleFile, index) =>
                    singleFile && (
                      <div className="detail" key={index}>
                        <img src={DocumentImage} alt="detail icon" />

                        <div>
                          <span className="title">{singleFile.name}</span>
                          <span className="size">{singleFile.size}</span>
                        </div>
                        <img
                          src={CloseIcon}
                          alt="close icon"
                          className="close"
                          onClick={() => removeItemFromList(index)}
                        />
                      </div>
                    )
                )
              : null}
          </>
        )}
      </div>
    </div>
  );
}

MultipleFileDropZoneComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
export default MultipleFileDropZoneComponent;
