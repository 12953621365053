import axios from "axios";
import APIRoutes from "./APIRoutes.json";
import * as ReduxActions from "./ReduxActions";

const CallAPI = async (
  type,
  data,
  id,
  setLoader,
  setApiError,
  navigate = null
) => {
  let APISingleObject = APIRoutes[type];
  if (setLoader) setLoader(true);
  if (setApiError) setApiError("");
  let result = await SendCallToServer(data, id, APISingleObject);
  if (result.error) {
    if (setApiError && result.error !== 403 && result.error !== 401) {
      setApiError(result.error);
    }
    if (setLoader) setLoader(false);
    return {
      status: 0,
      error: result.error,
    };
  } else {
    let returnedData = result.data;
    if (APISingleObject.callback) {
      returnedData = await ReduxActions[APISingleObject.callback](
        result.data,
        navigate
      );
    }
    if (setLoader) setLoader(false);
    return { status: 1, data: returnedData };
  }
};
const SendCallToServer = (data, id, APISingleObject) => {
  let url = APISingleObject.url;
  if (id) url += id;
  let method = APISingleObject.method;
  axios.defaults.headers.common["token_type"] = APISingleObject.token_type
    ? APISingleObject.token_type
    : "";

  return axios[method](url, data);
};
export { CallAPI };
