export const BreadCrumbsListing = {
  dashboard: {
    breadcrumbs: ["Dashboard"],
    component: "Dashboard",
    url: "dashboard",
  },
  setting: {
    breadcrumbs: ["Settings"],
    component: "Settings",
    url: "setting",
  },
  program: {
    breadcrumbs: ["Programs", "All Program"],
    component: "Programs",
    url: "program",
  },
  add_program: {
    breadcrumbs: ["Programs", "Add Program"],
    component: "Add Program",
    url: "program",
  },
  edit_program: {
    breadcrumbs: ["Programs", "Edit Program"],
    component: "Edit Program",
    url: "program",
  },
  program_detail: {
    breadcrumbs: ["Programs", "Program Detail"],
    component: "Program Detail",
    url: "program",
  },
  program_courses: {
    breadcrumbs: ["Programs", "Courses List"],
    component: "Program Detail",
    url: "program",
  },
  course: {
    breadcrumbs: ["Courses", "All Courses"],
    component: "All Courses",
    url: "course",
  },
  add_course: {
    breadcrumbs: ["Courses", "Add Course"],
    component: "Add Course",
    url: "course",
  },
  edit_course: {
    breadcrumbs: ["Courses", "Edit Course"],
    component: "Edit Course",
    url: "course",
  },
  course_detail: {
    breadcrumbs: ["Courses", "View Course Details"],
    component: "Course Detail",
    url: "course",
  },
  course_modules: {
    breadcrumbs: ["Courses", "Modules & Units"],
    component: "Course Module & Units",
    url: "course",
  },
  users: {
    breadcrumbs: ["Users", "All Users"],
    component: "All Users",
    url: "users",
  },
  modules: {
    breadcrumbs: ["Modules", "All Modules"],
    component: "All Modules",
    url: "modules",
  },
  add_module: {
    breadcrumbs: ["Modules", "Add Module"],
    component: "Add Module",
    url: "modules",
  },
  edit_module: {
    breadcrumbs: ["Modules", "Edit Module"],
    component: "Edit Module",
    url: "modules",
  },
  module_detail: {
    breadcrumbs: ["Modules", "View Module Detail"],
    component: "Module Detail",
    url: "modules",
  },
  module_units: {
    breadcrumbs: ["Modules", "Module Units"],
    component: "Module Units",
    url: "modules",
  },
  units: {
    breadcrumbs: ["Units", "All Units"],
    component: "Units",
    url: "units",
  },
  add_unit: {
    breadcrumbs: ["Units", "Add Unit"],
    component: "Add Unit",
    url: "units",
  },
  edit_unit: {
    breadcrumbs: ["Units", "Edit Unit"],
    component: "Edit Unit",
    url: "units",
  },
  unit_detail: {
    breadcrumbs: ["Units", "View Unit Detail"],
    component: "Unit Detail",
    url: "units",
  },
  tags: {
    breadcrumbs: ["Tags", "All Tags"],
    component: "All Tags",
    url: "tags",
  },
  categories: {
    breadcrumbs: ["Categories", "All Categories"],
    component: "All Categories",
    url: "categories",
  },
  profile: {
    breadcrumbs: ["Profile", "My Profile"],
    component: "Profile Overview",
    url: "profile",
  },
  subscription: {
    breadcrumbs: ["Subscription", "All Subscriptions"],
    component: "All Subscriptions",
    url: "subscription",
  },
  add_subscription: {
    breadcrumbs: ["Subscription", "Add Subscription"],
    component: "Add Subscription",
    url: "subscription",
  },
  schedule: {
    breadcrumbs: ["Schedules", "All Schedules"],
    component: "Schedules",
    url: "schedule",
  },
  add_schedule: {
    breadcrumbs: ["Schedules", "Add Schedule"],
    component: "Add Schedule",
    url: "schedule",
  },
  edit_schedule: {
    breadcrumbs: ["Schedules", "Edit Schedule"],
    component: "Edit Schedule",
    url: "schedule",
  },
  schedule_detail: {
    breadcrumbs: ["Schedules", "Schedule Detail"],
    component: "Schedule Detail",
    url: "schedule",
  },
  seasons: {
    breadcrumbs: ["Seasons", "All Seasons"],
    component: "All Seasons",
    url: "seasons",
  },
  enroll_schedules: {
    breadcrumbs: ["Enrollments", "Enroll Schedule"],
    component: "Enroll Schedule",
    url: "enrollments",
  },
  enrollments: {
    breadcrumbs: ["Enrollments", "All Enrollments"],
    component: "All Enrollments",
    url: "enrollments",
  },
  enrollment_detail: {
    breadcrumbs: ["Enrollments", "Enrollment Details"],
    component: "Enrollment Details",
    url: "enrollments",
  },
  enrollment_courses: {
    breadcrumbs: ["Enrollments", "Courses List"],
    component: "Courses List",
    url: "enrollments",
  },
  enrollment_modules: {
    breadcrumbs: ["Enrollments", "Modules List"],
    component: "Modules List",
    url: "enrollments",
  },
  enrollment_units: {
    breadcrumbs: ["Enrollments", "Units List"],
    component: "Units List",
    url: "enrollments",
  },
  enrollment_notes: {
    breadcrumbs: ["Enrollments", "Notes Details"],
    component: "Notes Details",
    url: "enrollments",
  },
  enrollment_progress: {
    breadcrumbs: ["Enrollments", "Progress"],
    component: "Progress",
    url: "enrollments",
  },
  logs: {
    breadcrumbs: ["Transaction Logs", "All Logs"],
    component: "Transaction Logs",
    url: "logs",
  },
};
