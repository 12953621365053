import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CallAPI } from "actions/General";
import { useTranslation } from "react-i18next";
import TextComponent from "./TextComponent";
import SelectionArrow from "assets/images/general_old/selection-arrow.svg";
import { getArrayDifference, getArrayMatched } from "config/config";

function SelectionComponent({
  type,
  items,
  updateItems,
  error,
  allowSorting,
  updatedRecordId = null,
}) {
  const { t } = useTranslation();
  const [listingRecords, setListingRecords] = useState([]);
  const updateFormValues = (updatedList) => {
    updateItems(type, updatedList);
  };
  const fetchRecordsList = async () => {
    if (listingRecords.length === 0) {
      const APIName =
        type === "pre_req_courses" ||
        type === "compulsory_courses" ||
        type === "elective_courses"
          ? "FETCH_COURSES_LIST"
          : type === "modules"
          ? "FETCH_MODULES_LIST"
          : "FETCH_UNITS_LIST";
      let result = await CallAPI(
        APIName,
        { status: "PUBLISHED" },
        null,
        null,
        null,
        null
      );
      if (result.status) {
        let newItemsList = [];
        let data =
          type === "pre_req_courses"
            ? result.data.courses.map((singleCourse) => {
                items.map((singleItem) => {
                  if (singleItem.id === singleCourse.course_id)
                    newItemsList.push({
                      id: singleCourse.course_id,
                      title: singleCourse.title,
                      version_id: singleCourse.id,
                    });
                });
                if (updatedRecordId !== singleCourse.id)
                  return {
                    id: singleCourse.course_id,
                    title: singleCourse.title,
                    version_id: singleCourse.id,
                  };
              })
            : type === "compulsory_courses" || type === "elective_courses"
            ? result.data.courses
            : type === "modules"
            ? result.data.modules
            : result.data.units;
        if (!data) data = [];
        if (type === "pre_req_courses") {
          data = data.filter(Boolean);
        }
        if (type === "pre_req_courses") {
          updateItems(type, newItemsList);
        }
        setListingRecords(data);
      }
    }
  };

  useEffect(() => {
    fetchRecordsList();
  }, []);
  return (
    <section className="item-detail-section">
      <header>{type !== "" ? t("itemListing.header_" + type) : ""}</header>
      <div className="detail">
        <div className="selection-lists">
          <SingleSelectionList
            updateListItems={updateFormValues}
            allList={listingRecords}
            selectedList={items}
            allowSorting={allowSorting}
            type={"forward"}
            itemType={type}
          />
          <SingleSelectionList
            updateListItems={updateFormValues}
            allList={listingRecords}
            selectedList={items}
            allowSorting={allowSorting}
            type={"backward"}
            itemType={type}
          />
        </div>
        <div className="form mb-4 d-flex flex-column gap-1">
          {error && <span className="error">{error}</span>}
        </div>
      </div>
    </section>
  );
}
SelectionComponent.propTypes = {
  error: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  allowSorting: PropTypes.bool.isRequired,
  updatedRecordId: PropTypes.string,
};
export default SelectionComponent;
const SingleSelectionList = ({
  allList,
  selectedList,
  updateListItems,
  type,
  itemType,
}) => {
  const [search, setSearch] = useState("");
  const [itemsList, setItemsList] = useState([]);

  const shiftSingleItem = (shiftedItem) => {
    if (type === "forward") {
      let addedItem = null;
      if (itemType === "pre_req_courses")
        addedItem = {
          id: shiftedItem.id,
          version_id: shiftedItem.version_id,
          title: shiftedItem.title,
        };
      else addedItem = { id: shiftedItem.id, title: shiftedItem.title };
      updateListItems([...selectedList, addedItem]);
    } else {
      const filteredItemsList = selectedList.filter(
        (singleItem) => singleItem.id !== shiftedItem.id
      );
      updateListItems(filteredItemsList);
    }
  };
  const shiftAllItem = () => {
    if (type === "forward") {
      const filteredItemsList = [];
      for (let index = 0; index < allList.length; index++) {
        const element = allList[index];
        let addedItem = null;
        if (itemType === "pre_req_courses")
          addedItem = {
            id: element.id,
            version_id: element.version_id,
            title: element.title,
          };
        else addedItem = { id: element.id, title: element.title };
        filteredItemsList.push(addedItem);
      }

      updateListItems(filteredItemsList);
    } else {
      updateListItems([]);
    }
  };
  const setSearchValue = (name, value) => {
    setSearch(value);
    let allItemsList = allList;
    if (value !== "") {
      allItemsList = allItemsList.filter(
        (singleItem) =>
          singleItem.title.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    }
    const items =
      type === "forward"
        ? getArrayDifference(allItemsList, selectedList, "array")
        : getArrayMatched(allItemsList, selectedList);
    setItemsList(items);
  };
  useEffect(() => {
    setSearchValue("", "");
  }, [allList, selectedList]);
  return (
    <div className="single-selection-box">
      <div className="form-label mb-0">
        {itemType === "pre_req_courses" ||
        itemType === "elective_courses" ||
        itemType === "compulsory_courses"
          ? type === "forward"
            ? "All Courses"
            : "Selected Courses"
          : itemType === "modules"
          ? type === "forward"
            ? "All Modules"
            : "Selected Modules"
          : type === "forward"
          ? "All Units"
          : "Selected Units"}
      </div>
      <TextComponent
        name="search"
        label=""
        placeholder="search"
        value={search}
        setValue={setSearchValue}
        error=""
        classes={"mb-0"}
        required={false}
      />
      <div className="items">
        <span onClick={shiftAllItem}>
          <img
            className={`${type === "backward" ? "rotate-180" : ""}`}
            src={SelectionArrow}
            alt="move items"
          />
        </span>
        {itemsList &&
          itemsList.map((singleItem, index) => {
            return (
              <span
                className="form-label"
                key={index}
                onClick={() => shiftSingleItem(singleItem)}
              >
                {singleItem.title}
              </span>
            );
          })}
      </div>
    </div>
  );
};
SingleSelectionList.propTypes = {
  allList: PropTypes.array.isRequired,
  selectedList: PropTypes.array.isRequired,
  updateListItems: PropTypes.func.isRequired,
  allowSorting: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};
