import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseImage from "assets/images/general_old/close.svg";
import SwitchBusiness from "assets/images/general_old/switch-business.svg";
import ResetPassword from "assets/images/general_old/reset-password.svg";
import DefaultUser from "assets/images/general_old/user-avatar.png";
import RightArrow from "assets/images/general_old/right-arrow.svg";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { authState } from "recoil/Auth";
import ButtonComponent from "./ButtonComponent";
import { getImagePreviewUrl } from "config/config";
import { useTranslation } from "react-i18next";

function ProfileMenu({ setDisplayProfileMenu }) {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState("");
  const userDetail = useRecoilValue(authState);
  const resetState = useResetRecoilState(authState);

  const logout = () => {
    resetState();
  };
  const getImageUrl = async () => {
    if (userDetail.details.avatar) {
      let url = await getImagePreviewUrl(userDetail.details.avatar);
      if (url) setImageUrl(url);
    }
  };
  useEffect(() => {
    getImageUrl();
  }, []);
  return (
    <div id="profileDetails" className="profile_menu">
      <div
        onClick={() => setDisplayProfileMenu(false)}
        className="overlay"
      ></div>
      <div className="detail">
        <div>
          <img
            onClick={() => setDisplayProfileMenu(false)}
            className="close-icon"
            src={CloseImage}
            alt="close"
          />
          <div>
            <span className="user-role">
              {userDetail.role === "BUSINESS_ADMIN"
                ? "Admin"
                : userDetail.role === "TEACHER"
                ? "Teacher"
                : userDetail.role === "STUDENT"
                ? "Student"
                : "Super Admin"}{" "}
              {t("texts.profile")}
            </span>
          </div>
          <div className="user-detail">
            <div className="d-flex gap-3">
              <img
                className="w-25"
                src={userDetail?.details?.avatar ? imageUrl : DefaultUser}
                alt="user"
              />
              <div className="d-flex flex-column gap-1 p-0 justify-content-center">
                <span className="name">
                  {userDetail.role === "SUPER_ADMIN"
                    ? "Super Admin"
                    : userDetail?.details?.name}
                </span>
                <span className="email">
                  {userDetail.role === "SUPER_ADMIN"
                    ? userDetail.email
                    : userDetail?.details?.email}
                </span>
              </div>
            </div>
          </div>
          {userDetail.role !== "SUPER_ADMIN" && (
            <>
              <div className="user-permission">
                <div className="d-flex gap-3 justify-content-between">
                  <span className="flex-1">{t("texts.role")}</span>
                  <span className="flex-1">
                    {userDetail?.details?.role_name}
                  </span>
                </div>
                <div className="d-flex gap-3 justify-content-between">
                  <span className="flex-1">{t("texts.profile")}</span>
                  <span className="flex-1">
                    {userDetail?.details?.profile_name}
                  </span>
                </div>
                <div className="d-flex gap-3 justify-content-between">
                  <span className="flex-1">{t("texts.department")}</span>
                  <span className="flex-1">
                    {userDetail?.details?.department_name}
                  </span>
                </div>
                <div className="d-flex gap-3 justify-content-between">
                  <span className="flex-1">{t("texts.business")}</span>
                  <span className="flex-1">
                    {userDetail?.details?.business_name}
                  </span>
                </div>
              </div>
              <div className="switch-business">
                <img src={SwitchBusiness} alt="switch business" />
                <span>{t("texts.switch_business")}</span>
                <img src={RightArrow} alt="select business" />
              </div>
            </>
          )}
          {/* <div className="reset-password">
            <img src={ResetPassword} alt="reset password" />
            <span>{t("texts.reset_password")}</span>
            <img src={RightArrow} alt="reset password" />
          </div> */}
        </div>
        <div className="logout">
          <ButtonComponent
            onClick={logout}
            text="logout"
            variant="blue-1 w-100"
            loading={false}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
}

ProfileMenu.propTypes = {
  setDisplayProfileMenu: PropTypes.func.isRequired,
};
export default ProfileMenu;
