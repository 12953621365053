import ANNOUNCEMENT_ICON from "assets/images/sidebar/announcement.svg";
import CATEGORY_ICON from "assets/images/sidebar/category.svg";
import CERTIFICATE_ICON from "assets/images/sidebar/certificate.svg";
import COURSE_ICON from "assets/images/sidebar/course.svg";
import DASHBOARD_ICON from "assets/images/sidebar/dashboard.svg";
import ENROLLMENT_ICON from "assets/images/sidebar/enrollment.svg";
import EVENT_ICON from "assets/images/sidebar/event.svg";
import GIFT_CARD_ICON from "assets/images/sidebar/gift_card.svg";
import MODULE_ICON from "assets/images/sidebar/module.svg";
import PROFILE_ICON from "assets/images/sidebar/profile.svg";
import PROGRAM_ICON from "assets/images/sidebar/program.svg";
import SCHEDULE_ICON from "assets/images/sidebar/schedule.svg";
import SETTING_ICON from "assets/images/sidebar/setting.svg";
import SUBSCRIPTION_ICON from "assets/images/sidebar/subscription.svg";
import TAG_ICON from "assets/images/sidebar/tag.svg";
import TRANSACTION_LOG_ICON from "assets/images/sidebar/transaction_logs.svg";
import UNIT_ICON from "assets/images/sidebar/unit.svg";
import USERS_ICON from "assets/images/sidebar/users.svg";

export const sideBarMenuItemsList = [
  {
    icon: DASHBOARD_ICON,
    title: "Dashboard",
    url: "dashboard",
    type: ["dashboard"],
    allowedFor: [],
  },
  {
    icon: PROGRAM_ICON,
    title: "Program",
    url: "program",
    type: [
      "program",
      "add_program",
      "edit_program",
      "program_detail",
      "program_courses",
    ],
  },
  {
    icon: COURSE_ICON,
    title: "Courses",
    url: "course",
    type: [
      "course",
      "add_course",
      "edit_course",
      "course_detail",
      "course_modules",
    ],
  },
  {
    icon: MODULE_ICON,
    title: "Modules",
    url: "modules",
    type: [
      "modules",
      "add_module",
      "edit_module",
      "module_detail",
      "module_units",
    ],
    allowedFor: ["SUPER_ADMIN", "BUSINESS_ADMIN"],
  },
  {
    icon: UNIT_ICON,
    title: "Units",
    url: "units",
    type: ["units", "add_unit", "edit_unit", "unit_detail"],
    allowedFor: ["SUPER_ADMIN", "BUSINESS_ADMIN"],
  },
  {
    icon: CATEGORY_ICON,
    title: "Categories",
    url: "categories",
    type: ["categories"],
    allowedFor: ["SUPER_ADMIN", "BUSINESS_ADMIN"],
  },
  {
    icon: TAG_ICON,
    title: "Tags",
    url: "tags",
    type: ["tags"],
    allowedFor: ["SUPER_ADMIN", "BUSINESS_ADMIN"],
  },
  {
    icon: SCHEDULE_ICON,
    title: "Schedules",
    url: "schedule",
    type: ["schedule", "add_schedule", "edit_schedule"],
    allowedFor: ["BUSINESS_ADMIN", "SUPER_ADMIN"],
  },
  // {
  //   icon: ENROLLMENT_ICON,
  //   title: "Enrollments",
  //   url: "enrollments",
  //   type: ["enrollments"],
  //   allowedFor: [],
  // },
  {
    icon: EVENT_ICON,
    title: "Events",
    url: "event",
    type: ["event"],
    allowedFor: [],
  },
  {
    icon: CERTIFICATE_ICON,
    title: "Certificates",
    url: "certificates",
    type: ["certificates"],
    allowedFor: [],
  },
  {
    icon: TRANSACTION_LOG_ICON,
    title: "Transaction Log",
    url: "logs",
    type: ["logs"],
    allowedFor: [],
  },
  {
    icon: USERS_ICON,
    title: "Users",
    url: "users",
    type: ["users"],
    allowedFor: [],
  },
  {
    icon: GIFT_CARD_ICON,
    title: "Gift Cards",
    url: "gift_cards",
    type: ["gift_cards"],
    allowedFor: [],
  },
  {
    icon: SUBSCRIPTION_ICON,
    title: "Subscription",
    url: "subscription",
    type: [
      "subscription",
      "add_subscription",
      "subscription_response/success",
      "subscription_response/fail",
    ],
    allowedFor: ["SUPER_ADMIN", "STUDENT"],
  },
  {
    icon: ANNOUNCEMENT_ICON,
    title: "Announcements",
    url: "announcement",
    type: ["announcement"],
    allowedFor: [],
  },
  {
    icon: PROFILE_ICON,
    title: "Profile",
    url: "profile",
    type: ["profile"],
    allowedFor: [],
  },
  {
    icon: SETTING_ICON,
    title: "Settings",
    url: "setting",
    type: ["setting"],
    allowedFor: ["BUSINESS_ADMIN"],
  },
  {
    icon: SETTING_ICON,
    title: "Seasons",
    url: "seasons",
    type: ["seasons"],
    allowedFor: ["SUPER_ADMIN"],
  },
  {
    icon: ENROLLMENT_ICON,
    title: "Enrollments",
    url: "enrollments",
    type: [
      "enrollments",
      "enroll_schedules",
      "enrollment_detail",
      "enrollment_courses",
      "enrollment_modules",
      "enrollment_units",
      "enrollment_notes",
      "enrollment_progress",
    ],
    allowedFor: ["STUDENT"],
  },
  {
    icon: ENROLLMENT_ICON,
    title: "Transaction Logs",
    url: "logs",
    type: ["logs"],
    allowedFor: ["STUDENT", "BUSINESS_ADMIN"],
  },
  {
    icon: "",
    title: "",
    url: "error",
    type: ["error"],
  },
];
