import React from "react";
import PropTypes from "prop-types";
import TextComponent from "components/TextComponent";
import SelectWithTextComponent from "components/SelectWithTextComponent";
import TextAreaComponent from "components/TextAreaComponent";

function Certification({ values, errors, updateFormValues }) {
  return (
    <div className="mb-4 box-width-flex">
      <div>
        <TextComponent
          name={"completed_percentage"}
          label={"completed_percentage"}
          placeholder={"completed_percentage"}
          value={values.completed_percentage}
          setValue={updateFormValues}
          classes={""}
          error={errors["completed_percentage"] ?? ""}
          type="number"
          required={true}
        />
        <TextComponent
          name={"quiz_percentage"}
          label={"quiz_percentage"}
          placeholder={"quiz_percentage"}
          value={values.quiz_percentage}
          setValue={updateFormValues}
          classes={""}
          error={errors["quiz_percentage"] ?? ""}
          type="number"
          required={true}
        />
        <SelectWithTextComponent
          name={"validity_period"}
          name1={"validity_type"}
          label={"validity_period"}
          placeholder={"validity_period"}
          value={values.validity_period}
          value1={values.validity_type}
          setValue={updateFormValues}
          classes={""}
          error={errors["validity_period"] ?? errors["validity_type"] ?? ""}
          type="number"
          optionsList={[
            { value: "", displayValue: "Select" },
            { value: "MONTHS", displayValue: "Months" },
            { value: "YEARS", displayValue: "Years" },
          ]}
          required={true}
        />
      </div>
      <div>
        <TextAreaComponent
          name={"cert_description"}
          label={"cert_description"}
          placeholder={"cert_description"}
          value={values.cert_description}
          error={errors["cert_description"] ?? ""}
          setValue={updateFormValues}
          classes={"w-100"}
          required={true}
        />
      </div>
    </div>
  );
}
Certification.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default Certification;
