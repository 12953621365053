import React from "react";
import LogoWhite from "assets/images/general/logo-light.svg";
import MenuToggle from "assets/images/sidebar/arrow.svg";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { generalState } from "recoil/General";
import { getUserRole } from "recoil/Auth";
import { sideBarMenuItemsList } from "config/MenuItemsList";
import DisplaySVG from "./DisplaySVG";

function SideBar() {
  const location = useLocation();
  const userRole = useRecoilValue(getUserRole);
  const [commonSetting, setCommonSettings] = useRecoilState(generalState);

  return (
    <div
      id="kt_aside"
      className={`aside aside-dark aside-hoverable`}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        <div className="d-flex align-items-center gap-3">
          <img src={LogoWhite} className="h-25px logo" alt={"white logo"} />
          <span className="text-white fw-bold">BlackForce LMS</span>
        </div>
        <div
          id="kt_aside_toggle"
          className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="body"
          data-kt-toggle-name="aside-minimize"
          onClick={() =>
            setCommonSettings({
              ...commonSetting,
              sidebar_opened: !commonSetting.sidebar_opened,
            })
          }
        >
          <DisplaySVG path={MenuToggle} classes={"svg-icon-1 rotate-180"} />
        </div>
      </div>
      <div className="aside-menu flex-column-fluid">
        <div
          id="kt_aside_menu_wrapper"
          className="hover-scroll-overlay-y my-5 my-lg-5"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="0"
        >
          <div
            id="#kt_aside_menu"
            data-kt-menu="true"
            className={
              "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            }
          >
            {sideBarMenuItemsList.map((singleMenuItem, index) => {
              if (
                singleMenuItem.title &&
                (!singleMenuItem.allowedFor ||
                  singleMenuItem.allowedFor.indexOf(userRole) > -1)
              )
                return (
                  <div className="menu-item" key={index}>
                    <Link
                      className={`menu-link without-sub ${
                        singleMenuItem.type.indexOf(
                          location.pathname.replace(location.pathname[0], "")
                        ) > -1
                          ? "active"
                          : ""
                      }`}
                      to={singleMenuItem.url}
                    >
                      <span className="menu-icon">
                        <DisplaySVG
                          path={singleMenuItem.icon}
                          classes="svg-icon-2"
                        />
                      </span>
                      <span className="menu-title">{singleMenuItem.title}</span>
                    </Link>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
