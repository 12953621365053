import React from "react";
import PropTypes from "prop-types";
import EditIcon from "assets/images/form/edit-icon.svg";
import DeactivateIcon from "assets/images/form/deactivate-icon.svg";
import ActivateIcon from "assets/images/form/activate-icon.png";
import DetailIcon from "assets/images/form/detail-view.svg";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "recoil/Auth";
import { useRecoilValue } from "recoil";

function UnitActions({ data, props }) {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const editUnit = () => {
    if (data.is_active)
      navigate("/edit_unit", { state: { update_id: data.id } });
    else props.actionOnDeactivatedUnit();
  };
  const viewUnitDetail = () => {
    if (data.is_active)
      navigate("/unit_detail", { state: { record_id: data.id } });
    else props.actionOnDeactivatedUnit();
  };
  const deleteUnit = async () => {
    props.deactivateUnitConfirmation(data.id, data.is_active);
  };

  return (
    <div className="d-flex gap-3 table-actions">
      {userRole === "STUDENT" || userRole === "TEACHER" ? (
        <img onClick={viewUnitDetail} src={DetailIcon} alt="edit-icon" />
      ) : userRole === "BUSINESS_ADMIN" ? (
        <>
          <img onClick={viewUnitDetail} src={DetailIcon} alt="edit-icon" />
          <img onClick={editUnit} src={EditIcon} alt="edit-icon" />
          <img
            onClick={deleteUnit}
            src={data.is_active ? DeactivateIcon : ActivateIcon}
            alt="edit-icon"
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
UnitActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default UnitActions;
