import { CallAPI } from "actions/General";
import ButtonComponent from "components/ButtonComponent";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PurchaseSubscription from "./PurchaseSubscription";
import DisplayHtml from "views/DisplayHtml";

function AddSubscription() {
  const { t } = useTranslation();
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [subscriptionsViewType, setSubscriptionsViewType] = useState("monthly");
  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const [purchasedSubscription, setPurchasedSubscription] = useState("");
  const getAllSubscriptionsList = async () => {
    const result = await CallAPI(
      "FETCH_SUBSCRIPTIONS_LIST",
      null,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSubscriptionsList(result.data);
    }
  };
  useEffect(() => {
    getAllSubscriptionsList();
  }, []);
  const openSubscriptionModal = (id) => {
    setPurchasedSubscription(id);
  };
  return (
    <>
      <div className="card subscriptions ">
        {loader && <LoaderComponent classes={"mx-4"} />}
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <h1 className="m-5">Add Subscription</h1>
        <hr />
        <div className="select-type">
          <span
            onClick={() => setSubscriptionsViewType("monthly")}
            className={`${subscriptionsViewType === "monthly" ? "active" : ""}`}
          >
            Monthly
          </span>
          <span
            onClick={() => setSubscriptionsViewType("yearly")}
            className={`${subscriptionsViewType === "yearly" ? "active" : ""}`}
          >
            Yearly
          </span>
        </div>
        <div className="subscription-items">
          {subscriptionsList &&
            subscriptionsList
              .filter(
                (singleSubscription) =>
                  singleSubscription.type ===
                  subscriptionsViewType.toUpperCase()
              )
              .map((singleSubscription, index) => {
                return (
                  <div className="item" key={index}>
                    <div>
                      <span className="title">{singleSubscription.title}</span>
                      <span className="credits">
                        {singleSubscription.credits}
                        <span>{t("texts.credits_earned")}</span>
                      </span>
                      <span className="detail">
                        <DisplayHtml html={singleSubscription.detail} />
                      </span>
                    </div>
                    <ButtonComponent
                      text="purchase"
                      loading={false}
                      disabled={false}
                      variant="blue-1"
                      onClick={() =>
                        openSubscriptionModal(singleSubscription.id)
                      }
                      langVal={singleSubscription.price}
                      langKey={"price"}
                    />
                  </div>
                );
              })}
        </div>
      </div>
      {purchasedSubscription && (
        <PurchaseSubscription
          dataToSend={{ subscriptionId: purchasedSubscription }}
          onClose={() => setPurchasedSubscription("")}
          type="subscription"
        />
      )}
    </>
  );
}

export default AddSubscription;
