import React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import EditIcon from "assets/images/form/edit-icon.svg";

function SubscriptionActions({ props, data }) {
  const userRole = useRecoilValue(getUserRole);

  const editSubscription = () => {
    props.setEditId(data.id);
  };

  return (
    <>
      {userRole === "SUPER_ADMIN" && (
        <div className="d-flex gap-3 table-actions">
          <img onClick={editSubscription} src={EditIcon} alt="edit-icon" />
        </div>
      )}
    </>
  );
}
SubscriptionActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default SubscriptionActions;
