import React from "react";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/Auth";

function ViewRoleDetails() {
  const userDetails = useRecoilValue(authState);
  return (
    <div className="p-4 mx-3 d-flex gap-4 flex-column">
      <div className="detail-single-item">
        <span>Email</span>
        <span>{userDetails?.email}</span>
      </div>
      <div className="detail-single-item">
        <span>Business Name</span>
        <span>{userDetails?.details?.business_name}</span>
      </div>
      <div className="detail-single-item">
        <span>Role</span>
        <span>{userDetails?.details?.role_name}</span>
      </div>
      <div className="detail-single-item">
        <span>Department</span>
        <span>{userDetails?.details?.department_name}</span>
      </div>
      <div className="detail-single-item">
        <span>Profile</span>
        <span>{userDetails?.details?.profile_name}</span>
      </div>
    </div>
  );
}

export default ViewRoleDetails;
