import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function SelectComponent({
  name,
  label,
  classes,
  optionsList,
  value,
  onChange,
  error,
  required,
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row mb-5 ${classes}`}>
      {label && (
        <label className={"form-label"}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <select
        className={"form-select form-select-lg form-select-solid"}
        value={value}
        name={name}
        onChange={(e) => onChange(name, e.target.value)}
      >
        {optionsList.map((singleOption, index) => {
          return (
            <option key={index} value={singleOption.value}>
              {singleOption.displayValue}
            </option>
          );
        })}
      </select>
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}
SelectComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  optionsList: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
export default SelectComponent;
