import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize } from "config/config";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import {
  StudentSubscriptionTableHeader,
  SuperAdminSubscriptionTableHeader,
} from "./utils";
import EditSubscription from "./EditSubscription";

function Subscriptions() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const [editId, setEditId] = useState("");
  // const [displayType, setDisplayType] = useState("list");
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(DefaultPaginationSize);
  const addSubscription = () => {
    navigate("/add_subscription");
  };
  const autoChargeCancelRequest = () => {
    console.log("Auto Charge Cancellation Request");
  };
  const getSubscriptionsList = async () => {
    const APIName =
      userRole === "SUPER_ADMIN"
        ? "GET_ALL_SUBSCRIPTION_PLANS"
        : "GET_MY_SUBSCRIPTIONS";
    const APIBody = userRole === "SUPER_ADMIN" ? null : { page, pageLimit };
    setLoaderType("get_listing");
    const result = await CallAPI(
      APIName,
      APIBody,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) setSubscriptionsData(result.data);
  };
  useEffect(() => {
    getSubscriptionsList();
  }, [page]);
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={
            userRole === "SUPER_ADMIN"
              ? "subscriptions_listing"
              : "my_subscriptions_listing"
          }
          setDisplayType={() => {}}
          displayType={""}
          addItem={
            userRole === "SUPER_ADMIN"
              ? autoChargeCancelRequest
              : addSubscription
          }
        />
        <div className={"card-body py-4"}>
          <TableComponent
            data={subscriptionsData}
            header={
              userRole === "SUPER_ADMIN"
                ? SuperAdminSubscriptionTableHeader
                : StudentSubscriptionTableHeader
            }
            type="subscription"
            props={{ setEditId }}
          />
        </div>
      </div>
      {editId && (
        <EditSubscription
          editId={editId}
          setEditId={setEditId}
          getSubscriptionsList={getSubscriptionsList}
        />
      )}
    </>
  );
}

export default Subscriptions;
