import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";

function DatePickerComponent({
  name,
  label,
  placeholderText,
  classes,
  selectedDate,
  onChange,
  error,
  minDate,
  maxDate,
  required,
  disabled,
  options,
  defaultValue = new Date().getTime(),
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row mb-5 ${classes}`}>
      {label && (
        <label className={"form-label"}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <div className="customDatePickerWidth">
        <Flatpickr
          disabled={disabled}
          value={selectedDate}
          defaultValue={defaultValue ? defaultValue.toString() : null}
          onChange={([date]) => {
            onChange(name, moment(date).unix() * 1000);
          }}
          className="form-control form-control-lg form-control-solid"
          placeholder="Pick date"
          options={options}
        />
      </div>
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}

DatePickerComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  classes: PropTypes.string,
  selectedDate: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  options: PropTypes.object,
  minDate: PropTypes.number,
  defaultValue: PropTypes.number,
  maxDate: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePickerComponent;
