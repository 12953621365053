import React from "react";
import PropTypes from "prop-types";
import ItemListingComponent from "components/ItemListingComponent";
import SelectionComponent from "components/SelectionComponent";

function NotesUnits({ values, errors, updateFormValues }) {
  return (
    <div className="d-flex flex-column gap-4 w-100">
      <SelectionComponent
        type={"units"}
        items={values.units}
        updateItems={updateFormValues}
        error={errors["units"] ?? ""}
        allowSorting={true}
      />
      <ItemListingComponent
        type={"documents"}
        items={values.documents}
        updateItems={updateFormValues}
        error={errors["documents"] ?? ""}
      />
      <ItemListingComponent
        type={"urls"}
        items={values.urls}
        updateItems={updateFormValues}
        error={errors["urls"] ?? ""}
      />
    </div>
  );
}
NotesUnits.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default NotesUnits;
