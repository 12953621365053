import React, { useEffect, useState } from "react";
import BackIcon from "assets/images/general_old/back.svg";
import LoaderComponent from "components/LoaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import AccordionComponent from "components/AccordionComponent";
import { CallAPI } from "actions/General";

function CourseModules() {
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedAccordion, setSelectedAccordion] = useState(-1);
  const getCourseModuleUnits = async (id) => {
    const result = await CallAPI(
      "GET_COURSE_MODULES_AND_UNITS",
      {},
      id,
      setLoader,
      null,
      null
    );
    if (result.status) {
      const details = result.data;
      setValues({
        title: details.course_title,
        modules: details.modules.map((singleModule) => {
          return { title: singleModule.title };
        }),
        units: details.modules.map((singleModule) =>
          singleModule.units.map((singleUnit) => singleUnit.title)
        ),
      });
    }
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getCourseModuleUnits(location.state.record_id);
  }, [location]);
  const backToCourses = () => {
    navigate("/course");
  };
  return (
    <div className="card py-7 course-admin-detail">
      {loader ? (
        <LoaderComponent classes="" />
      ) : (
        <>
          <div className="header-detail">
            <img onClick={backToCourses} src={BackIcon} alt="back" />
            <span className="title">{values.title}</span>
          </div>
          <div className="detail">
            <span className="title">Modules & Units</span>
            <AccordionComponent
              items={values.modules}
              selectedAccordion={selectedAccordion}
              setSelectedAccordion={setSelectedAccordion}
              variant={"dual"}
            >
              <div className="units-list">
                {selectedAccordion !== -1 &&
                  values.units &&
                  values.units[selectedAccordion].map((singleUnit) => (
                    <span>{singleUnit}</span>
                  ))}
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
}

export default CourseModules;
