import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CallAPI } from "actions/General";
import LoaderComponent from "components/LoaderComponent";
import GridView from "components/GridView";
import { GridModulesHeader } from "./utils";
function EnrollmentModulesUnitsList({
  enrollment_id,
  type,
  module_id,
  timeToStart = 0,
}) {
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState(null);
  const getAppropriateDetails = async () => {
    const apiName = "GET_COURSE_MODULES_UNITS_LIST";
    const recordType =
      type === "program"
        ? "course"
        : type === "course"
        ? "module"
        : type === "module"
        ? "unit"
        : "";
    const result = await CallAPI(
      apiName,
      {
        id: enrollment_id,
        type: recordType.toUpperCase(),
        ...(module_id ? { module_id: module_id } : {}),
      },
      null,
      setLoader,
      null,
      null
    );
    if (result.status) {
      const data = result.data;
      setDetails(data);
    }
  };
  useEffect(() => {
    getAppropriateDetails();
  }, []);
  return (
    <>
      {loader && <LoaderComponent classes="" />}
      {details && (
        <GridView
          data={details}
          columns={GridModulesHeader}
          options={{
            dataToSend: { enrollment_id, getAppropriateDetails },
            type: `${timeToStart > 0 ? "" : "enrollment"}`,
          }}
          type={"type-1 type-2"}
        />
      )}
    </>
  );
}
EnrollmentModulesUnitsList.propTypes = {
  enrollment_id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  course_id: PropTypes.string,
  program_id: PropTypes.string,
  module_id: PropTypes.string,
  timeToStart: PropTypes.number,
};
export default EnrollmentModulesUnitsList;
