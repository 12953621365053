export const ScheduleGridHeader = {
  header: [{ callbackFunction: "displayTitleWithSlug" }],
  body: [
    {
      key: "teacher.name",
      displayValue: "teacher",
    },
    {
      key: "season.title",
      displayValue: "season",
      callbackFunction: "displaySeason",
    },
    {
      key: "year",
      displayValue: "year",
    },
    {
      key: "",
      displayValue: "duration",
      callbackFunction: "displayDuration",
    },
    {
      key: "schedule_date",
      displayValue: "start_date",
      callbackFunction: "populateDateTime",
    },
    {
      key: "enrollment_end_date",
      displayValue: "enrollment_end_date",
      callbackFunction: "populateDate",
    },
  ],
};
export const EnrollmentsTableHeader = [
  { key: "title", displayValue: "title", sort: true },
  { key: "type", displayValue: "type", sort: true },
  {
    key: "enrollment_date",
    displayValue: "enrollment_date",
    sort: true,
    callbackFunction: "populateDate",
  },
  {
    key: "schedule_date",
    displayValue: "start_date",
    sort: true,
    callbackFunction: "populateDateTime",
  },
  {
    key: "start_in",
    displayValue: "start_in",
    sort: true,
    callbackFunction: "convertSeconds",
  },
  {
    key: "expiration_date",
    displayValue: "enrollment_completion_date",
    sort: true,
    callbackFunction: "populateDate",
  },
  {
    key: "status",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayEnrollmentStatus",
  },
];
export const EnrollmentsGridHeader = {
  header: [
    { callbackFunction: "displayTitleWithSlug" },
    { callbackFunction: "displayIsFreeField" },
  ],
  body: [
    {
      key: "enroll_credits",
      displayValue: "enroll_credits",
    },
    {
      key: "reward_credits",
      displayValue: "reward_credits",
    },
    {
      key: "language",
      displayValue: "language",
    },
    {
      key: "tags",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
    {
      key: "status",
      displayValue: "status",
      callbackFunction: "displayUnitStatus",
    },
  ],
};
export const GridModulesHeader = {
  header: [
    { callbackFunction: "displayTitleWithSlug" },
    { callbackFunction: "displayEnrollmentStatus" },
  ],
  body: [
    {
      key: "credits",
      displayValue: "credits",
    },
    {
      key: "competency_level",
      displayValue: "competency_level",
    },
    {
      key: "tagsList",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
  ],
};
export const ModalButtons = {
  confirmDelete: {
    text: "delete_enrollment_record",
    buttons: [
      {
        text: "drop",
        variant: "red-1",
        onClick: "deactivateEnrollment",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "enrollment_record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
