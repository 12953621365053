import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function PasswordComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,
  error,
  required,
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row mb-5`}>
      <label className={`form-label`}>
        <span className={`${required ? "required" : ""}`}>
          {label ? t("fields." + label) : ""}
        </span>
      </label>
      <input
        className={"form-control form-control-lg form-control-solid"}
        type="password"
        placeholder={t("fields." + placeholder)}
        value={value}
        name={name}
        onChange={(e) => setValue(name, e.target.value)}
      />
      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}
PasswordComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
export default PasswordComponent;
