import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";

function AccessDetails({ values, errors, updateFormValues, otherDetails }) {
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <SelectComponent
            name={"department"}
            label={"select_department"}
            value={values.department}
            error={errors["department"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.departments}
            required={true}
          />
        </div>
        <div>
          <SelectComponent
            name={"role"}
            label={"select_role"}
            value={values.role}
            error={errors["role"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.roles}
            required={true}
          />
        </div>
        <div>
          <SelectComponent
            name={"profile"}
            label={"select_profile"}
            value={values.profile}
            error={errors["profile"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.profiles}
            required={true}
          />
        </div>
      </div>
    </>
  );
}
AccessDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default AccessDetails;
