import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import TextComponent from "components/TextComponent";
import DatePickerComponent from "components/DatePickerComponent";
import SelectWithTextComponent from "components/SelectWithTextComponent";
import ChooseProgSchedule from "./ChooseProgSchedule";

function SelectScheduleDetails({
  values,
  errors,
  updateFormValues,
  updateFormMultipleValues,
  otherDetails,
}) {
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <TextComponent
            name={"year"}
            label={"select_year"}
            placeholder={"year"}
            value={values.year}
            setValue={(name, value) => {
              updateFormMultipleValues({
                [name]: value,
                schedule_date: null,
                enrollment_end_date: null,
              });
            }}
            classes={""}
            error={errors["year"] ?? ""}
            required={true}
            type="number"
          />

          <SelectComponent
            name={"season_id"}
            label={"select_season"}
            value={values.season_id}
            error={errors["season_id"] ?? ""}
            classes={""}
            onChange={(name, value) => {
              updateFormMultipleValues({
                [name]: value,
                schedule_date: null,
                enrollment_end_date: null,
              });
            }}
            optionsList={otherDetails.seasons}
            required={false}
          />

          <DatePickerComponent
            name="schedule_date"
            onChange={(name, value) => {
              updateFormMultipleValues({
                [name]: value,
                enrollment_end_date: null,
              });
            }}
            selectedDate={values.schedule_date}
            label="select_schedule_date"
            required={true}
            error={errors["schedule_date"] ?? ""}
            disabled={!values.year || !values.season_id}
            defaultValue={
              otherDetails.seasons.filter(
                (singleFilter) => singleFilter.value === values.season_id
              ).length > 0
                ? otherDetails.seasons.filter(
                    (singleFilter) => singleFilter.value === values.season_id
                  )[0].startDate
                : null
            }
            options={{
              enableTime: true,
              enable: [
                function (date) {
                  if (values.year) {
                    const newDate = new Date(date).getTime();
                    const selectedSeason = otherDetails.seasons.filter(
                      (singleFilter) => singleFilter.value === values.season_id
                    );
                    let isDateAllowed = false;
                    if (selectedSeason && selectedSeason.length > 0) {
                      let dateArray = [
                        selectedSeason[0].startDate,
                        selectedSeason[0].endDate,
                      ];
                      const startDate = new Date(
                        new Date(dateArray[0]).setFullYear(values.year)
                      ).getTime();
                      let endDate = new Date(
                        new Date(dateArray[1]).setFullYear(values.year)
                      ).getTime();
                      if (dateArray[0] > dateArray[1]) {
                        endDate = new Date(
                          new Date(dateArray[1]).setFullYear(
                            parseInt(values.year) + 1
                          )
                        ).getTime();
                      }
                      if (
                        newDate > startDate &&
                        newDate < endDate &&
                        newDate > new Date().getTime()
                      )
                        isDateAllowed = true;
                    }
                    return isDateAllowed;
                  } else return false;
                },
              ],
            }}
          />

          <DatePickerComponent
            name="enrollment_end_date"
            label="enrollment_end_date"
            placeholderText="Select enrollment end date"
            selectedDate={values.enrollment_end_date}
            onChange={updateFormValues}
            error={errors["enrollment_end_date"] ?? ""}
            required={true}
            maxDate={values.schedule_date}
            options={{
              enable: [
                function (date) {
                  if (values.year) {
                    const newDate = new Date(date).getTime();
                    let isDateAllowed = false;
                    const startDate = new Date(values.schedule_date).getTime();
                    if (newDate < startDate && newDate > new Date().getTime())
                      isDateAllowed = true;
                    return isDateAllowed;
                  } else return false;
                },
              ],
            }}
          />
          <DatePickerComponent
            name="cancellation_date"
            label="cancellation_date"
            placeholderText="Select Last Cancellation date"
            selectedDate={values.cancellation_date}
            onChange={updateFormValues}
            error={errors["cancellation_date"] ?? ""}
            required={true}
            maxDate={values.schedule_date}
            options={{
              enable: [
                function (date) {
                  if (values.year) {
                    const newDate = new Date(date).getTime();
                    let isDateAllowed = false;
                    const startDate = new Date(values.schedule_date).getTime();
                    if (newDate < startDate && newDate > new Date().getTime())
                      isDateAllowed = true;
                    return isDateAllowed;
                  } else return false;
                },
              ],
            }}
          />
          <SelectWithTextComponent
            name={"duration"}
            name1={"duration_type"}
            label={"duration"}
            placeholder={"duration"}
            value={values.duration}
            value1={values.duration_type}
            setValue={updateFormValues}
            classes={""}
            error={errors["duration"] ?? errors["duration_type"] ?? ""}
            type="number"
            optionsList={[
              { value: "", displayValue: "Select" },
              { value: "MINUTES", displayValue: "Minutes" },
              { value: "HOURS", displayValue: "Hours" },
            ]}
            required={true}
          />
        </div>
        {values.type === "PROGRAM" && (
          <div>
            <ChooseProgSchedule
              values={values}
              otherDetails={otherDetails}
              updateFormValues={updateFormValues}
            />
          </div>
        )}
      </div>
    </>
  );
}
SelectScheduleDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  updateFormMultipleValues: PropTypes.func.isRequired,
};
export default SelectScheduleDetails;
