import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import DropZoneAdd from "assets/images/general_old/dropzone_add.svg";
import CloseImage from "assets/images/general_old/close-grey.svg";
import { CallAPI } from "actions/General";
import { getImagePreviewUrl } from "config/config";
import { useTranslation } from "react-i18next";

function SingleFileDropZoneComponent({
  name,
  value,
  label,
  classes,
  setValue,
  error,
  uploadText,
  required,
}) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isFilesUpdated, setIsFilesUpdated] = useState(false);
  const [loader, setLoader] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setIsFilesUpdated(true);
    },
  });

  useEffect(() => {
    if (files.length > 0 && isFilesUpdated) {
      setValue(name, files.length > 0 ? files[0] : null);
      setIsFilesUpdated(false);
    }
  }, [files, name, setValue, setIsFilesUpdated, isFilesUpdated]);

  const removeMedia = () => {
    setValue(name, null);
    setFiles([]);
  };

  useEffect(() => {
    const getPreviewImage = async () => {
      if (value && value.id) {
        let url = await getImagePreviewUrl(value.id, setLoader);
        if (url) {
          setImageUrl(url);
        }
      }
    };
    getPreviewImage();
  }, [value]);

  return (
    <div className={` drop-zone-component fv-row mb-5 ${classes}`}>
      <label className={`form-label`}>
        <span className={`${required ? "required" : ""}`}>
          {label ? t("fields." + label) : ""}
        </span>
      </label>
      {files.length > 0 || value ? (
        <>
          {!loader ? (
            <div className={`preview`}>
              {files && files.length > 0 ? (
                <img src={URL.createObjectURL(files[0])} alt="single logo" />
              ) : null}
              {value && value.id ? (
                <img src={imageUrl} alt="multiple logo" />
              ) : null}
              <img
                src={CloseImage}
                onClick={removeMedia}
                className="close-image"
                alt="close"
              />
            </div>
          ) : (
            <div>
              <div className={`spinner-border`}></div>
            </div>
          )}
          {classes.previewClass ? null : (
            <div className="mx-auto d-flex gap-3 align-items-center">
              {value && value.url && (
                <>
                  <span>{value.name}</span>
                  <span>{value.size}</span>
                </>
              )}
              {files && files.length > 0 && (
                <>
                  <span>{files[0].name}</span>
                  <span>{files[0].size}</span>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} multiple={false} />
          <img src={DropZoneAdd} alt="DropZoneAdd" />
          <span className="form-control form-control-lg form-control-solid">
            {uploadText ? uploadText : "Add Image"}
          </span>
        </div>
      )}
    </div>
  );
}

SingleFileDropZoneComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  value: PropTypes.object,
  error: PropTypes.string,
  uploadText: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
export default SingleFileDropZoneComponent;
