import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import ButtonComponent from "components/ButtonComponent";

function EnrollActions({ data, props }) {
  const userRole = useRecoilValue(getUserRole);
  const navigate = useNavigate();
  const viewEnrollment = () => {
    navigate("/enrollment_detail", { state: { record_id: data.id } });
  };
  const deactivateEnrollment = async () => {
    props.deactivateEnrollmentConfirmation(data.id);
  };

  return (
    <div className="d-flex gap-3 table-actions">
      {userRole === "STUDENT" ? (
        <>
          {data.status === "COMPLETED" ||
            (data.status === "IN_PROGRESS" && (
              <ButtonComponent
                text="drop"
                variant="grey-2"
                onClick={deactivateEnrollment}
                loading={false}
                disabled={false}
              />
            ))}
          <ButtonComponent
            text="enrollment details"
            variant="white-blue-1 px-2"
            onClick={viewEnrollment}
            loading={false}
            disabled={false}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
EnrollActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default EnrollActions;
