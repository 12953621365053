import React from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

function DisplaySVG({ path, classes, svgClassName = "mh-50px" }) {
  return (
    <span className={`svg-icon ${classes}`}>
      <SVG src={path} className={svgClassName} />
    </span>
  );
}
DisplaySVG.propType = {
  path: PropTypes.string.isRequired,
  svgClassName: PropTypes.string,
  classes: PropTypes.string,
};
export default DisplaySVG;
