import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import { CallAPI } from "actions/General";
import LoaderComponent from "components/LoaderComponent";
import DisplayHtml from "views/DisplayHtml";
import { FileIcon } from "react-file-icon";

function EnrollmentNotes() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [details, setDetails] = useState(null);
  const [recordId, setRecordId] = useState("");
  const [recordType, setRecordType] = useState("");
  const getNotesDocuments = async () => {
    setLoaderType("get_details");
    const result = await CallAPI(
      "GET_NOTES_DOCUMENTS_DETAILS",
      {
        record_id: recordId,
        record_type: recordType,
      },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setDetails(result.data);
    }
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    ) {
      setRecordId(location.state.record_id);
      setRecordType(location.state.record_type);
    }
  }, []);
  useEffect(() => {
    if (recordId !== "") getNotesDocuments();
  }, [recordId]);
  const markAsComplete = async () => {
    const result = await CallAPI(
      "COMPLETE_MODULE_UNIT",
      {},
      recordId,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      getNotesDocuments();
    }
  };
  return (
    <>
      {loader && loaderType === "get_details" ? (
        <LoaderComponent classes="" />
      ) : details ? (
        <div className="card">
          <ListingHeaderComponent
            type="notes_documents"
            title={details.title}
            addItem={details.can_completed ? markAsComplete : ""}
            displayType=""
            setDisplayType={() => {}}
          />
          <hr />
          <div className="py-8 px-13">
            <div className="card course-admin-detail">
              <div className="detail m-0">
                <span className="title">Description</span>
                <div className="learning-points-list bg-white py-8 px-10 theme-font-inter-medium notes_description">
                  <DisplayHtml html={details.description} />
                </div>
              </div>
            </div>
          </div>
          <div className="py-0 px-13">
            <div className="card course-admin-detail">
              <div className="detail m-0">
                <span className="title">Document</span>
                <div className="learning-points-list bg-white py-8 px-10 theme-font-inter-medium notes_docs">
                  {details.documents.map((singleDocument) => (
                    <FileIcon extension={singleDocument.extension} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="py-8 px-13">
            <div className="card course-admin-detail">
              <div className="detail m-0">
                <span className="title">Reference Links</span>
                <div className="learning-points-list bg-white py-8 px-10 theme-font-inter-medium notes_link">
                  {details.urls.map((singleUrl) => (
                    <a href={singleUrl} target="_blank">
                      {singleUrl}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default EnrollmentNotes;
