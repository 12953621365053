import React from "react";
import PropTypes from "prop-types";
import EditIcon from "assets/images/form/edit-icon.svg";

function SeasonsActions({ props, data }) {
  const editSeason = () => {
    props.setEditObj(data);
  };

  return (
    <div className="d-flex gap-3 table-actions">
      <img onClick={editSeason} src={EditIcon} alt="edit-icon" />
    </div>
  );
}
SeasonsActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default SeasonsActions;
