import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import JoditEditor from "jodit-react";
import { useTranslation } from "react-i18next";

function TextAreaComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,
  error,
  required,
}) {
  const config = useMemo(
    () => ({
      buttons: [
        "bold",
        "italic",
        "underline",
        "|",
        "align",
        "link",
        "|",
        "undo",
        "redo",
      ],
    }),
    []
  );
  const editor = useRef(null);

  const { t } = useTranslation();
  return (
    <div className={`fv-row mb-5 ${classes}`}>
      <label className={`form-label`}>
        <span className={`${required ? "required" : ""}`}>
          {label ? t("fields." + label) : ""}
        </span>
      </label>
      <JoditEditor
        className={"form-control form-control-lg form-control-solid"}
        placeholder={placeholder}
        name={name}
        ref={editor}
        value={value}
        config={config}
        tabIndex={1}
        onChange={(e) => {
          setValue(name, e);
        }}
      />

      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}
TextAreaComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
export default TextAreaComponent;
