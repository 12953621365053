import React, { useState, useEffect } from "react";
import { LogActions, LogTypes } from "./utils";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/ButtonComponent";
import TextComponent from "components/TextComponent";
import DateFill from "assets/images/general/date-fill.svg";
import { CallAPI } from "actions/General";
import DisplayHtml from "views/DisplayHtml";
import moment from "moment";
import { DefaultPaginationSize } from "config/config";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function LogsList() {
  const userRole = useRecoilValue(getUserRole);
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState("all");
  const [allowFilter, setAllowFilter] = useState(false);
  const [hasMoreLogs, setHasMoreLogs] = useState(false);
  const [search, setSearch] = useState("");
  const [logs, setLogs] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(DefaultPaginationSize);

  const fetchLogs = async (clearState = false) => {
    const result = await CallAPI("GET_LOGS", {
      log_type: selectedType,
      search: search,
      user_id: selectedUser,
      page,
      limit,
    });
    if (result.status) {
      const format = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const today = new Date().toLocaleDateString("en-GB", format);
      const apiLogs = result.data.reduce((acc, log) => {
        const date = new Date(log.record_date * 1000).toLocaleDateString(
          "en-GB",
          format
        );
        const key = date === today ? "Today" : date;
        return {
          ...acc,
          [key]: [...(acc[key] || []), log],
        };
      }, {});

      const merged = apiLogs;
      if (!(clearState || page === 1)) {
        // Loop through the API data and merge it based on the keys
        Object.keys(logs).map((item) => {
          // Check if the key exists, if not initialize it
          if (!merged[item]) {
            merged[item] = [];
          }

          // Merge the data for the current key
          merged[item] = [...logs[item], ...merged[item]];
        });
      }
      setLogs(merged);
      setHasMoreLogs(limit === result.data.length);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [page]);
  useEffect(() => {
    setLogs({});
    if (page === 1) {
      fetchLogs(true);
    } else setPage(1);
  }, [search, selectedType, selectedUser]);
  const fetchUsers = async () => {
    const result = await CallAPI("GET_LOGS_USERS", {}, null, null, null, null);
    if (result.status) {
      setUsers(result.data);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const formatLogMessage = (log) => {
    const {
      action,
      type: logType,
      created_by: { name: creatorName },
      detail,
      record_date: timestamp,
    } = log;
    const formattedTime = moment(timestamp * 1000).format("hh:mm A");

    const replacements = {
      "{{name}}": creatorName,
      "{{title}}": detail.title || "",
      "{{time}}": formattedTime,
      "{{enrollmentType}}": detail.type || "",
      "{{programTitle}}": detail.programTitle || "",
    };

    const effectiveType =
      logType === "enrollment" && replacements["{{programTitle}}"]
        ? `${logType}_in_program`
        : logType;

    const logMessageTemplate = LogActions[`${action}_${effectiveType}`];

    return Object.keys(replacements).reduce(
      (message, key) => message.replace(key, replacements[key]),
      logMessageTemplate
    );
  };

  return (
    <div className="card transaction_logs">
      <div className="type">
        {LogTypes.filter(
          (singleLogType) => singleLogType.validFor.indexOf(userRole) > -1
        ).map((singleLogType, index) => {
          return (
            <span
              key={index}
              className={`${selectedType === singleLogType.key && "active"}`}
              onClick={() => {
                setSelectedType(singleLogType.key);
              }}
            >
              {t("logs." + singleLogType.value)}
            </span>
          );
        })}
      </div>
      <div className="filters">
        <ButtonComponent
          variant="grey-1"
          text="filters"
          onClick={() => {
            setAllowFilter(!allowFilter);
          }}
          loading={false}
          disabled={false}
        />
      </div>
      <div className="logs">
        {userRole !== "STUDENT" && (
          <div className="users_list">
            <span className="specific_logs">
              {t("logs.user_specific_logs")}
            </span>
            <TextComponent
              name="search"
              placeholder="search"
              value={search}
              setValue={(name, value) => {
                setSearch(value);
              }}
              required={false}
              label=""
              classes=""
            />
            <span className={`user`} onClick={() => setSelectedUser("")}>
              All
            </span>
            {users.map((singleUser, index) => (
              <span
                key={index}
                className={`user ${
                  selectedUser === singleUser.id ? "active" : ""
                }`}
                onClick={() => setSelectedUser(singleUser.id)}
              >
                {singleUser.name}
              </span>
            ))}
          </div>
        )}
        <div className="logs_list">
          {Object.keys(logs).map((singleDay) => {
            return (
              <div key={singleDay} className="single_day">
                <div className="line">
                  <img src={DateFill} /> <div className="vertical"></div>
                </div>
                <div className="log_items">
                  <span className="date">{singleDay}</span>
                  {logs[singleDay].map((singleLog, index) => {
                    if (LogActions[singleLog.action + "_" + singleLog.type])
                      return (
                        <div key={index} className="single_item">
                          {<DisplayHtml html={formatLogMessage(singleLog)} />}
                        </div>
                      );
                    else
                      return (
                        <div key={index} className="single_item">
                          <span>
                            {singleLog.type}-{singleLog.action} is not defined
                          </span>
                        </div>
                      );
                  })}
                </div>
              </div>
            );
          })}
          {hasMoreLogs && (
            <span
              onClick={() => {
                setPage(page + 1);
              }}
              className="load-more ms-5"
            >
              {t("general.load_more")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogsList;
