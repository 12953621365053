import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "./SelectComponent";
import { paginationLimits } from "config/config";
import PaginationArrow from "assets/images/general/pagination_arrow.svg";

function PaginationComponent({
  totalPages,
  setPage,
  pageLimit,
  setPageLimit,
  currentPage,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center pagination-component">
      <div className="d-flex align-items-center bg-white w-auto ps-3 pe-4 ">
        <span className="form-label mb-0">Show</span>{" "}
        <span className="h-20px border-gray-200 border-start ms-4"></span>
        <SelectComponent
          value={pageLimit.toString()}
          name="pagination"
          label=""
          onChange={(name, value) => {
            setPageLimit(parseInt(value));
            setPage(1);
          }}
          optionsList={paginationLimits}
          classes={"pagination_select"}
          required={false}
        />
      </div>
      <div className="">
        <div id="kt_table_users_paginate">
          {totalPages > 0 && (
            <ul className="pagination">
              <li className="page-item previous">
                <a
                  className="page-link page-text"
                  onClick={() => {
                    if (currentPage > 1) setPage(1);
                  }}
                  disabled={true}
                  style={{ cursor: "pointer" }}
                >
                  <img src={PaginationArrow} alt="Pagination Arrow" />
                  <img src={PaginationArrow} alt="Pagination Arrow" />
                </a>
              </li>
              <li className="page-item previous">
                <a
                  className="page-link page-text"
                  onClick={() => {
                    if (currentPage > 1) setPage(currentPage - 1);
                  }}
                  disabled={true}
                  style={{ cursor: "pointer" }}
                >
                  <img src={PaginationArrow} alt="Pagination Arrow" />
                </a>
              </li>
              {Array.from(Array(totalPages).keys()).map((singleEntry) => {
                let page = singleEntry + 1;
                return (
                  <li
                    key={page}
                    className={`page-item ${
                      currentPage === page ? "active" : ""
                    }`}
                  >
                    <a
                      className={"page-link"}
                      onClick={() => setPage(page)}
                      style={{ cursor: "pointer" }}
                    >
                      {page}
                    </a>
                  </li>
                );
              })}
              <li className="page-item next">
                <a
                  className="page-link page-text"
                  onClick={() => {
                    if (currentPage < totalPages) setPage(currentPage + 1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="rotate-180"
                    src={PaginationArrow}
                    alt="Pagination Arrow"
                  />
                </a>
              </li>
              <li className="page-item next">
                <a
                  className="page-link page-text"
                  onClick={() => {
                    if (currentPage < totalPages) setPage(totalPages);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="rotate-180"
                    src={PaginationArrow}
                    alt="Pagination Arrow"
                  />{" "}
                  <img
                    className="rotate-180"
                    src={PaginationArrow}
                    alt="Pagination Arrow"
                  />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      {/* <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <span
          onClick={() => {
            if (currentPage !== 1) {
              setPage(1);
            }
          }}
          className={`item ${currentPage === 1 ? "disabled" : ""}`}
        >
          {"<<"}
        </span>
        <span
          onClick={() => {
            if (currentPage !== 1) {
              setPage(currentPage - 1);
            }
          }}
          className={`item ${currentPage === 1 ? "disabled" : ""}`}
        >
          {"<"}
        </span>
        {Array.from(Array(totalPages).keys()).map((singleEntry) => {
          let page = singleEntry + 1;
          if (
            page === 1 ||
            page === 2 ||
            page === currentPage ||
            page === currentPage - 1 ||
            page === currentPage + 1 ||
            page === totalPages ||
            page === totalPages - 1
          ) {
            return (
              <span
                key={page}
                onClick={() => {
                  setPage(page);
                }}
                className={`item ${
                  parseInt(currentPage) === parseInt(page) ? "active" : ""
                }`}
              >
                {page}
              </span>
            );
          } else if (page === 3 || page === totalPages - 2) {
            return (
              <span className="pagination-dots" key={page}>
                ...
              </span>
            );
          }
        })}
        <span
          onClick={() => {
            if (currentPage !== totalPages) {
              setPage(currentPage + 1);
            }
          }}
          className={`item ${currentPage === totalPages ? "disabled" : ""}`}
        >
          {">"}
        </span>
        <span
          onClick={() => {
            if (currentPage !== totalPages) {
              setPage(totalPages);
            }
          }}
          className={`item ${currentPage === totalPages ? "disabled" : ""}`}
        >
          {">>"}
        </span>
      </div> */}
    </div>
  );
}
PaginationComponent.propTypes = {
  setPageLimit: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};
export default PaginationComponent;
