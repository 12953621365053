import React, { useEffect, useState } from "react";
import BackIcon from "assets/images/general_old/back.svg";
import LoaderComponent from "components/LoaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";

function ModuleUnits() {
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);
  const getCourseModuleUnits = async (id) => {
    const result = await CallAPI(
      "GET_MODULES_UNITS",
      {},
      id,
      setLoader,
      null,
      null
    );
    if (result.status) {
      const details = result.data;
      setValues({
        title: details.module_title,
        units: details.units,
      });
    }
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getCourseModuleUnits(location.state.record_id);
  }, [location]);
  const backToCourses = () => {
    navigate("/modules");
  };
  return (
    <div className="card py-7 course-admin-detail">
      {loader ? (
        <LoaderComponent classes="" />
      ) : (
        <>
          <div className="header-detail">
            <img onClick={backToCourses} src={BackIcon} alt="back" />
            <span className="title">{values.title}</span>
          </div>
          <div className="detail">
            <span className="title">Units List</span>
            <div className="units-list">
              {values.units &&
                values.units.map((singleUnit) => (
                  <span className="no-accordion">{singleUnit}</span>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default ModuleUnits;
