import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
function EnrollmentModuleUnitActions({ data, otherDetails }) {
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const viewModulesUnits = () => {
    navigate(
      data.type === "MODULE" ? "/enrollment_units" : "/enrollment_modules",
      {
        state: {
          enrollment_id: data.enrollment_id,
          ...(data.type === "MODULE"
            ? { module_id: data.version_id, id: data.id }
            : {}),
        },
      }
    );
  };
  const viewNotesDocument = () => {
    navigate("/enrollment_notes", {
      state: {
        record_id: data.id,
        record_type:
          data.type === "COURSE"
            ? "course"
            : data.type === "MODULE"
            ? "module"
            : "unit",
      },
    });
  };
  const openModuleUnit = async () => {
    const result = await CallAPI(
      "START_MODULE_UNIT",
      {},
      data.id,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      otherDetails.getAppropriateDetails();
    }
  };
  const enrollCourse = () => {
    const detail = {
      type: "course",
      course_id: data.course_id,
      version_id: data.version_id,
      title: data.title,
      payment_type: data.is_free
        ? { type: "free" }
        : data.subscription
        ? { type: "subscription", slug: data.subscription.slug }
        : { type: "pay", method: data.payment_type },
    };
    navigate("/enroll_schedules", { state: detail });
  };
  return (
    <div className="d-flex flex-column align-items-center my-8 gap-3">
      {data.status === "NOT_STARTED" ? (
        <ButtonComponent
          text={`${
            data.type === "COURSE"
              ? "enroll"
              : data.type === "MODULE"
              ? "open_module"
              : "open_unit"
          }`}
          variant={`${
            data.type === "COURSE" ||
            (!data.has_in_progress &&
              data.sequence === data.completed_items + 1)
              ? "blue-1"
              : "grey-21"
          }`}
          loading={false}
          disabled={false}
          onClick={() => {
            if (
              data.type === "COURSE" ||
              (!data.has_in_progress &&
                data.sequence === data.completed_items + 1)
            ) {
              if (data.type === "COURSE") enrollCourse();
              else openModuleUnit();
            }
          }}
        />
      ) : (
        <>
          <ButtonComponent
            text="view_notes_documents"
            variant="white-blue-10 underline_with_column"
            loading={false}
            disabled={false}
            onClick={viewNotesDocument}
          />
          {(data.type === "COURSE" || data.type === "MODULE") && (
            <ButtonComponent
              text={data.type === "MODULE" ? "view_units" : "view_modules"}
              variant="white-blue-10 underline_with_column"
              loading={false}
              disabled={false}
              onClick={viewModulesUnits}
            />
          )}
        </>
      )}
    </div>
  );
}
EnrollmentModuleUnitActions.propTypes = { data: PropTypes.object.isRequired };
export default EnrollmentModuleUnitActions;
