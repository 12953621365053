import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextComponent from "components/TextComponent";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";

function AddTag({ getAllTags, editObj, setEditObj }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const updateValues = (name, value) => {
    setTitle(value);
  };
  useEffect(() => {
    if (editObj && editObj.id !== "") {
      setTitle(editObj.title);
    } else {
      setTitle("");
    }
  }, [editObj]);
  const addTag = async () => {
    if (!title) setError("Tag name is required");
    else {
      let APIPath = editObj && editObj.id ? "UPDATE_TAG" : "CREATE_TAG";
      let recordIdToUpdate = editObj && editObj.id ? editObj.id : null;
      setError("");
      let result = await CallAPI(
        APIPath,
        { title },
        recordIdToUpdate,
        setLoading,
        setApiError,
        null
      );
      if (result.status) {
        getAllTags("backward");
        setTitle("");
        if (editObj && editObj.id) setEditObj(null);
      }
    }
  };
  return (
    // <div>
    //   {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
    <div>
      <div className="create-title mb-3">{t("tags.create_tags_title")}</div>
      <div className="d-flex gap-3 align-items-start justify-content-start">
        <TextComponent
          name="add_tag"
          label=""
          error={error}
          placeholder="tag_name"
          value={title}
          setValue={updateValues}
          classes={""}
          required={true}
        />
        <ButtonComponent
          text={editObj && editObj.id ? "update" : "create"}
          variant="blue-1 type-1 mt-1"
          onClick={addTag}
          loading={loading}
          disabled={loading}
        />
      </div>
    </div>
  );
}

AddTag.propTypes = {
  getAllTags: PropTypes.func.isRequired,
  setEditObj: PropTypes.func.isRequired,
  editObj: PropTypes.object,
};
export default AddTag;
