import React from "react";
import PropTypes from "prop-types";
import ItemListingComponent from "components/ItemListingComponent";

function TagsLearnHighlights({ values, errors, updateFormValues }) {
  return (
    <div className="d-flex flex-column gap-4 w-100">
      <ItemListingComponent
        type={"tags"}
        items={values.tags}
        updateItems={updateFormValues}
        error={errors["tags"] ?? ""}
      />
      <ItemListingComponent
        type={"learning_points"}
        items={values.learning_points}
        updateItems={updateFormValues}
        error={errors["learning_points"] ?? ""}
      />
    </div>
  );
}
TagsLearnHighlights.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default TagsLearnHighlights;
