import React, { useEffect, useState } from "react";
import ProfileApproveIcon from "assets/images/general_old/profile-approve-icon.svg";
import TabsComponent from "components/TabsComponent";
import { profileTabs } from "./utils";
import ViewRoleDetails from "./ViewRoleDetails";
import UpdateDetails from "./UpdateDetails";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/Auth";
import DefaultUser from "assets/images/general_old/user-avatar.png";
import { getImagePreviewUrl } from "config/config";

function Profile() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const userDetails = useRecoilValue(authState);

  const [imageUrl, setImageUrl] = useState("");

  const getImageUrl = async () => {
    if (userDetails.details.avatar) {
      let url = await getImagePreviewUrl(userDetails.details.avatar, setLoader);
      if (url) setImageUrl(url);
    }
  };
  useEffect(() => {
    getImageUrl();
  }, [userDetails?.details?.avatar]);

  return (
    <div className="profile">
      <div className="details">
        <img
          src={!loader && userDetails?.details?.avatar ? imageUrl : DefaultUser}
          alt="Default User"
          className="w-100"
        />
        <h1>
          {userDetails?.details?.name}
          <img
            className="ms-2"
            src={ProfileApproveIcon}
            alt={"profile-approve-icon"}
          />
        </h1>
        <p>{userDetails?.details?.role_name}</p>
        <div className="horizontal-line"></div>
        <p>{userDetails?.details?.about}</p>
      </div>
      <div className="permissions">
        <TabsComponent
          tabs={profileTabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          type={1}
        >
          <div className="horizontal-line"></div>
          {selectedTab === 1 ? <ViewRoleDetails /> : <UpdateDetails />}
        </TabsComponent>
      </div>
    </div>
  );
}

export default Profile;
