export const UnitsTableHeader = [
  { key: "slug", displayValue: "code", sort: true },
  { key: "title", displayValue: "title", sort: true },
  {
    key: "competency_level",
    displayValue: "competency_level",
    sort: true,
    callbackFunction: "displayCompetencyLevel",
  },
  {
    key: "tags",
    displayValue: "tags",
    sort: true,
    callbackFunction: "displayTags",
  },
  {
    key: "status",
    displayValue: "status",
    sort: true,
    callbackFunction: "displayUnitStatus",
  },
];
export const UnitsGridHeader = {
  header: [{ callbackFunction: "displayTitleWithSlug" }],
  body: [
    {
      key: "competency_level",
      displayValue: "competency_level",
      callbackFunction: "displayCompetencyLevel",
    },
    {
      key: "tags",
      displayValue: "tags",
      callbackFunction: "displayTags",
    },
    {
      key: "status",
      displayValue: "status",
      callbackFunction: "displayUnitStatus",
    },
  ],
};
export const AddUnitTabs = [
  {
    displayValue: "basic_details",
  },
  {
    displayValue: "tags_highlights",
  },
  {
    displayValue: "notes_units",
  },
  {
    displayValue: "preview",
  },
];
export const ViewUnitDetailTabs = [
  {
    displayValue: "detail_view_description",
  },
  {
    displayValue: "detail_view_learn_section",
  },
  {
    displayValue: "detail_view_other_detail",
  },
];
export const ModalButtons = {
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "deactivate",
        variant: "red-1",
        onClick: "deactivateUnit",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  confirmActive: {
    text: "active_record",
    buttons: [
      {
        text: "activate",
        variant: "blue-1",
        onClick: "deactivateUnit",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordUpdate: {
    text: "record_update",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordCreated: {
    text: "record_created",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordActivated: {
    text: "record_activate",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  deactivateActionError: {
    text: "action_on_deactivated_error",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
