import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "components/CustomModal";

function SubscriptionResponse() {
  const navigate = useNavigate();
  let params = useParams();
  const isSuccess = params.type;
  const closeModal = () => {
    navigate("/subscription");
  };
  return (
    <div>
      <CustomModal
        variant={isSuccess === "success" ? "success" : "error"}
        text={
          isSuccess === "success"
            ? "subscription_purchased_success"
            : "subscription_purchased_fail"
        }
        header=""
        onClose={closeModal}
        buttons={[
          {
            text: "ok",
            variant: "blue-1",
            onClick: "closeModal",
            loading: false,
            disabled: false,
          },
        ]}
        functionsList={{ closeModal }}
      />
    </div>
  );
}

export default SubscriptionResponse;
