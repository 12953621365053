import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Logo from "assets/images/general/logo-light.svg";
import { CallAPI } from "actions/General";
import { useSetRecoilState } from "recoil";
import { authState } from "recoil/Auth";
import { LoginResponse } from "views/auth/utils";

function LoginWithKeys() {
  let params = useParams();
  params.module_id = parseInt(params.module_id);
  const setAuthSettingsDetail = useSetRecoilState(authState);
  const loginUserViaKeys = async (params) => {
    let result = await CallAPI(
      "LOGIN_USER_VIA_KEYS",
      params,
      null,
      null,
      null,
      null
    );
    if (result.status) {
      const authObject = LoginResponse(result.data);

      setAuthSettingsDetail(authObject);
    }
  };
  useEffect(() => {
    loginUserViaKeys(params);
  }, [params]);
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <h2 className="theme-font-inter-bold mb-0">Please Wait</h2>
        <div className="spinner-border"></div>
      </div>
    </div>
  );
}

export default LoginWithKeys;
