import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function TextRadioComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,
  required,
}) {
  const { t } = useTranslation();
  return (
    <div className={`fv-row position-relative ${classes ? classes : "mb-5"}`}>
      {label && (
        <label className={`form-label`}>
          <span className={`${required ? "required" : ""}`}>
            {t("fields." + label)}
          </span>
        </label>
      )}
      <input
        className={"form-control form-control-lg form-control-solid"}
        disabled
        placeholder={t("fields." + placeholder)}
        name={name}
        onChange={(e) => {}}
      />
      <label className="switch mt-3">
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => setValue(name, e.target.checked)}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
TextRadioComponent.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default TextRadioComponent;
