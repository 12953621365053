import React from "react";
import PropTypes from "prop-types";
import SelectionComponent from "components/SelectionComponent";

function PreReqCourses({ values, errors, updateFormValues, updatedRecordId }) {
  return (
    <div className="d-flex flex-column gap-4 w-100">
      <SelectionComponent
        type={"pre_req_courses"}
        items={values.pre_req_courses}
        updateItems={updateFormValues}
        error={errors["pre_req_courses"] ?? ""}
        allowSorting={true}
        updatedRecordId={updatedRecordId}
      />
    </div>
  );
}
PreReqCourses.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  updatedRecordId: PropTypes.string,
};
export default PreReqCourses;
