import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseImage from "assets/images/general_old/close.svg";
import TextComponent from "components/TextComponent";
import { CallAPI } from "actions/General";
import ButtonComponent from "components/ButtonComponent";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import DatePickerComponent from "components/DatePickerComponent";
import moment from "moment";

function EditSeason({ editObj, setEditObj, getSeasonsList }) {
  console.log(editObj);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [values, setValues] = useState({
    title: editObj.title,
    start_date: editObj.start_date * 1000,
    end_date: editObj.end_date * 1000,
  });
  const [errors, setErrors] = useState({
    title: "",
    start_date: "",
    end_date: "",
  });

  const createNormalizedDate = (dateString) => {
    const date = new Date(dateString);
    const currentYear = 2024; // Use the current year
    return new Date(currentYear, date.getMonth(), date.getDate()); // Set year to current year
  };

  // Function to check if two date ranges overlap
  const checkOverlap = (range1Start, range1End, range2Start, range2End) => {
    // Check if range1 overlaps with range2
    return (
      (range1Start <= range2End && range1Start >= range2Start) ||
      (range1End <= range2End && range1End >= range2Start) ||
      (range1Start <= range2Start && range1End >= range2End)
    );
  };

  const isValidDateRange = (seasonTitle, season, dateType, newDate) => {
    const normalizedNewDate = createNormalizedDate(newDate);
    const newSeason = {
      ...editObj.allSeasonDates[season],
      [dateType]: normalizedNewDate,
    };

    // Check for overlap with other seasons
    for (let otherSeason in editObj.allSeasonDates) {
      if (otherSeason === seasonTitle) continue;

      const otherSeasonStart = createNormalizedDate(
        editObj.allSeasonDates[otherSeason].start
      );
      const otherSeasonEnd = createNormalizedDate(
        editObj.allSeasonDates[otherSeason].end
      );

      if (dateType === "start_date") {
        const otherSeasonEndDate = createNormalizedDate(newSeason.end);
        if (
          checkOverlap(
            normalizedNewDate,
            otherSeasonEndDate,
            otherSeasonStart,
            otherSeasonEnd
          )
        ) {
          return false;
        }
      }

      if (dateType === "end_date") {
        const otherSeasonStartDate = createNormalizedDate(newSeason.start);
        if (
          checkOverlap(
            normalizedNewDate,
            otherSeasonStartDate,
            otherSeasonStart,
            otherSeasonEnd
          )
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const updateFormValues = (name, value) => {
    console.log(name, value);
    let isAllowedToUpdate = true;
    if (name !== "title") {
      isAllowedToUpdate = false;
      isAllowedToUpdate = isValidDateRange(
        values.title,
        editObj.allSeasonDates[values.title],
        name,
        value
      );
    }
    if (isAllowedToUpdate) {
      setValues({ ...values, [name]: value });
      setErrors({
        ...errors,
        [name]: "",
      });
    } else
      setErrors({
        ...errors,
        [name]: "Date lies in some other season, please select another date",
      });
  };

  const updateSeason = async () => {
    setLoaderType("save");
    console.log("values", values);
    let result = await CallAPI(
      "UPDATE_SEASON_DETAIL",
      {
        title: values.title,
        start_date: parseInt(values.start_date / 1000),
        end_date: parseInt(values.end_date / 1000),
      },
      editObj.id,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setEditObj({});
      getSeasonsList();
    }
  };
  return (
    <div className={`custom-modal `}>
      {loader && loaderType === "load_detail" && <LoaderComponent classes="" />}
      {apiError && <ErrorComponent text={apiError} variant="danger" />}
      <div className="detail subscriptions">
        <img
          className="close"
          src={CloseImage}
          onClick={() => setEditObj({})}
          alt="close"
        />
        <div className="d-flex flex-column gap-3">
          <TextComponent
            classes={""}
            name={"title"}
            value={values.title}
            setValue={updateFormValues}
            error={""}
            label={"title"}
            placeholder={"title"}
            disabled={true}
            required={true}
          />
          <DatePickerComponent
            name="start_date"
            onChange={updateFormValues}
            selectedDate={values.start_date}
            label="start_date"
            required={true}
            minDate={values.start_date}
            maxDate={values.end_date}
            classes="hide-calendar-year"
            error={errors["start_date"]}
            options={{
              dateFormat: "d F",
              disable: [
                function (date) {
                  const newDate = new Date(date).getTime();
                  // return true to disable
                  let isDateAllowed = false;
                  Object.keys(editObj.allSeasonDates).map((singleSeason) => {
                    if (
                      newDate >= editObj.allSeasonDates[singleSeason].start &&
                      newDate <= editObj.allSeasonDates[singleSeason].end &&
                      singleSeason !== values.title
                    )
                      isDateAllowed = true;
                  });
                  console.log("isDateAllowed", isDateAllowed, date);
                  return isDateAllowed;
                },
              ],
            }}
          />
          <DatePickerComponent
            name="end_date"
            onChange={updateFormValues}
            selectedDate={values.end_date}
            label="end_date"
            required={true}
            error={errors["end_date"]}
            options={{
              dateFormat: "d F",
              disable: [
                function (date) {
                  const newDate = new Date(date).getTime();
                  // return true to disable
                  let isDateAllowed = false;
                  Object.keys(editObj.allSeasonDates).map((singleSeason) => {
                    if (
                      newDate >= editObj.allSeasonDates[singleSeason].start &&
                      newDate <= editObj.allSeasonDates[singleSeason].end &&
                      singleSeason !== values.title
                    )
                      isDateAllowed = true;
                  });
                  console.log("isDateAllowed", isDateAllowed, date);
                  return isDateAllowed;
                },
              ],
            }}
          />
          <div className="ms-auto">
            <ButtonComponent
              text="update"
              onClick={updateSeason}
              variant="blue-1"
              loading={loader && loaderType === "save"}
              disabled={loader && loaderType === "save"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
EditSeason.propTypes = {
  editObj: PropTypes.object.isRequired,
  setEditObj: PropTypes.func.isRequired,
  getSeasonsList: PropTypes.func.isRequired,
};
export default EditSeason;
