import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DisplayHtml from "views/DisplayHtml";
import { getImagePreviewUrl } from "config/config";
import DEFAULT_IMG from "assets/images/general/default-image.svg";
import COURSE_TICK from "assets/images/general/course-tick.svg";

function Preview({ values }) {
  const [imageUrl, setImageUrl] = useState("");
  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  useEffect(() => {
    if (values && values.banner && values.banner.id)
      getPreviewImg(values.banner.id);
  }, [values]);
  return (
    <div className="preview">
      <div className="item">
        <img
          src={
            values.banner && values.banner.id
              ? imageUrl
              : values.banner
              ? URL.createObjectURL(values.banner)
              : DEFAULT_IMG
          }
          alt="Unit Image"
        />
        <div className="top">
          <div className="title">
            <span className="text-gray-800 fw-bold">Title:</span>
            <span className="text-gray-800">{values.title}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold ">Category:</span>
            <span className="text-gray-800">{values.category_id}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Enrolled Points:</span>
            <span className="text-gray-800">{values.enroll_credits}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Reward Points:</span>
            <span className="text-gray-800">{values.reward_credits}</span>
          </div>
        </div>
      </div>
      <div className="item mt-5">
        <span className="text-gray-800 fw-bold">Description:</span>
        <div>
          <span className="text-gray-800">
            <DisplayHtml html={values.detail} />
          </span>
        </div>
      </div>
      <div className="item flex-row mb-3">
        <span className="text-gray-800 fw-bold">Tags: </span>
        <div>
          <span className="text-gray-800">
            {values.tags.map((singleTag) => singleTag.title).join(", ")}
          </span>
        </div>
      </div>
      <div className="item courses">
        <div>
          <span className="text-gray-800 fw-bold">Compulsory Courses</span>
          <ul className="d-flex flex-column gap-3">
            {values.compulsory_courses.map((singleCourse, index) => {
              return (
                <li className="text-gray-800" key={index}>
                  <img src={COURSE_TICK} alt="tick" />
                  {singleCourse.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <span className="text-gray-800 fw-bold">Elective Courses</span>
          <ul className="d-flex flex-column gap-3">
            {values.elective_courses.map((singleCourse, index) => {
              return (
                <li className="text-gray-800" key={index}>
                  <img src={COURSE_TICK} alt="tick" />
                  {singleCourse.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <span className="text-gray-800 fw-bold">Pre Requisite Courses</span>
          <ul className="d-flex flex-column gap-3">
            {values.pre_req_courses.map((singleCourse, index) => {
              return (
                <li className="text-gray-800" key={index}>
                  <img src={COURSE_TICK} alt="tick" />
                  {singleCourse.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <span className="text-muted text-gray-800 fw-bold">
            What Will You Learn
          </span>
          <ul className="d-flex flex-column gap-3">
            {values.learning_points.map((singleLearningPoint, index) => {
              return (
                <li className="text-gray-800" key={index}>
                  <img src={COURSE_TICK} alt="tick" />
                  {singleLearningPoint.text}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
Preview.propTypes = {
  values: PropTypes.object.isRequired,
};
export default Preview;
