import * as yup from "yup";
export const BasicDetailsSchema = yup.object({
  title: yup.string().required("Title is required"),
  reward_credits: yup.string().required("Reward Credits is required"),
  competency_level: yup.string().required("Select Competency Level"),
  detail: yup.string().required("Description is required"),
});
export const TagsLearnHighlightsSchema = yup.object({
  tags: yup.array().required().min(1, "Add at least one tag"),
  learning_points: yup
    .array()
    .required()
    .min(1, "Add at least one learning point"),
});
export const NotesUnitsSchema = yup.object({
  units: yup.array().required().min(1, "Add at least one Unit"),
  documents: yup.array().required().min(1, "Add at least one document/note"),
  urls: yup.array().required().min(1, "Add at least one url"),
});
