import React from "react";
import PropTypes from "prop-types";

function AccordionComponent({
  items,
  selectedAccordion,
  setSelectedAccordion,
  variant,
  children,
}) {
  return (
    <div className={`accordion ${variant}`}>
      {items &&
        items.map((singleAccordion, index) => {
          return (
            <div
              className={`item ${index === selectedAccordion ? "active" : ""}`}
              key={index}
            >
              <span
                onClick={() => {
                  if (index === selectedAccordion) setSelectedAccordion(-1);
                  else setSelectedAccordion(index);
                }}
              >
                {singleAccordion.title}
              </span>
              {index === selectedAccordion && (
                <div className="itemDetail">{children}</div>
              )}
            </div>
          );
        })}
    </div>
  );
}
AccordionComponent.propType = {
  items: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
  setSelectedAccordion: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.number.isRequired,
  children: PropTypes.any,
};
export default AccordionComponent;
