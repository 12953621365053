const SuperAdminLogin = (data) => {
  const authObject = {
    authToken: data.access_token,
    email: data.email,
    id: data.id,
    role: data.role,
  };
  return authObject;
};

export { SuperAdminLogin };
