import React, { useState } from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import ButtonComponent from "components/ButtonComponent";
import scheduleCalenderIcon from "../../assets/images/general/schedule-calender.png";
function ChooseProgSchedule({
  values,
  errors,
  updateFormValues,
  otherDetails,
}) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const handleCourseChange = (name, value) => {
    setSelectedCourse(value);
    setSelectedSchedule(null);
  };
  const handleScheduleChange = (name, value) => setSelectedSchedule(value);
  const addSchedule = () => {
    if (selectedCourse && selectedSchedule) {
      const selectedCourseData = otherDetails.program_courses_schedules.find(
        (course) => course.course_id === selectedCourse
      );

      const newEntry = {
        course_id: selectedCourse,
        course_title: selectedCourseData.course_title,
        schedule_id: selectedSchedule,
        schedule_title: `${
          selectedCourseData.course_schedules.find(
            (schedule) => schedule.schedule_id === selectedSchedule
          ).season_name
        } ${
          selectedCourseData.course_schedules.find(
            (schedule) => schedule.schedule_id === selectedSchedule
          ).year
        }`,
      };

      if (
        !values.courses_schedules.some(
          (item) =>
            item.course_id === newEntry.course_id &&
            item.schedule_id === newEntry.schedule_id
        )
      ) {
        updateFormValues("courses_schedules", [
          ...values.courses_schedules,
          newEntry,
        ]);
        resetSelection();
      }
    }
  };

  const resetSelection = () => {
    setSelectedCourse(null);
    setSelectedSchedule(null);
  };

  const handleRemoveItem = (course_id, schedule_id) => {
    const updatedSchedules = values.courses_schedules.filter(
      (item) =>
        !(item.course_id === course_id && item.schedule_id === schedule_id)
    );
    updateFormValues("courses_schedules", updatedSchedules);
  };

  const nullItem = { value: null, displayValue: "Select" };

  const courseOptions = [
    nullItem,
    ...otherDetails.program_courses_schedules
      .filter(
        (course) =>
          !values.courses_schedules.some(
            (scheduledCourse) => scheduledCourse.course_id === course.course_id
          )
      )
      .map((course) => ({
        value: course.course_id,
        displayValue: course.course_title,
      })),
  ];

  const courseSchedules =
    otherDetails.program_courses_schedules.find(
      (course) => course.course_id === selectedCourse
    )?.course_schedules || [];

  const scheduleOptions =
    selectedCourse && courseSchedules.length > 0
      ? [
          nullItem,
          ...courseSchedules.map((schedule) => ({
            value: schedule.schedule_id,
            displayValue: `${schedule.season_name} ${schedule.year}`,
          })),
        ]
      : [];

  return (
    <div className="schedule-container">
      <div className="heading">
        <img src={scheduleCalenderIcon} alt="schedule-icon" />
        <h4>Select Schedule for Program Course</h4>
      </div>

      <div className="selected-courses-list">
        <ul>
          {values.courses_schedules.map((entry, index) => (
            <li key={index} className="selected-course-item">
              <strong>{entry.course_title}</strong> - {entry.schedule_title}
              <span
                className="remove-button"
                onClick={() =>
                  handleRemoveItem(entry.course_id, entry.schedule_id)
                }
              >
                &times;
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="select-row">
        <div className="select-label">
          <SelectComponent
            name="course_id"
            label="select_course"
            value={selectedCourse}
            classes="select-component"
            onChange={handleCourseChange}
            optionsList={courseOptions}
            required
          />
        </div>
        <div className="select-label">
          <SelectComponent
            name="schedule_id"
            label="select_schedule"
            value={selectedSchedule}
            classes="select-component"
            onChange={handleScheduleChange}
            optionsList={
              scheduleOptions.length > 0
                ? scheduleOptions
                : [{ value: "", displayValue: "No Schedule Found" }]
            }
            required
          />
        </div>
        {selectedCourse && selectedSchedule && (
          <ButtonComponent
            text="add_schedule_to_list"
            onClick={addSchedule}
            variant="blue-1"
            className="add-schedule-button"
          />
        )}
        {console.log(scheduleOptions)}
        {scheduleOptions.length === 0 && selectedCourse && (
          <ButtonComponent
            text="add_schedule"
            onClick={addSchedule}
            variant="red-1"
            className="add-schedule-button"
          />
        )}
      </div>
    </div>
  );
}

ChooseProgSchedule.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};

export default ChooseProgSchedule;
