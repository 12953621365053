import React, { useEffect, useState } from "react";
import LoaderComponent from "components/LoaderComponent";
import TabsComponent from "components/TabsComponent";
import { SettingTabs } from "./utils";
import AccessLevelSettings from "./AccessLevelSettings";
import PaymentSettings from "./PaymentSettings";
import { CallAPI } from "actions/General";

function BusinessSettings() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [values, setValues] = useState({});

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const getBusinessSettings = async () => {
    const result = await CallAPI(
      "GET_BUSINESS_SETTING",
      {},
      null,
      null,
      null,
      null
    );
    if (result.status) {
      setValues(result.data);
    }
  };

  useEffect(() => {
    getBusinessSettings();
  }, []);

  return (
    <>
      {loader && loaderType === "get_details" && <LoaderComponent classes="" />}
      <div className="card">
        <div
          className="card-body custom-form"
          id="custom-form"
          style={{ "--noOfColumn": 3 }}
        >
          <TabsComponent
            tabs={SettingTabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          >
            <div className="mx-auto w-100 pt-15 pb-10 form-details">
              {selectedTab === 1 ? (
                <AccessLevelSettings
                  values={values}
                  setValues={updateFormValues}
                />
              ) : selectedTab === 2 ? (
                <PaymentSettings values={values} setValues={updateFormValues} />
              ) : (
                <></>
              )}
            </div>
          </TabsComponent>
        </div>
      </div>
    </>
  );
}

export default BusinessSettings;
