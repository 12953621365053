import React, { useState } from "react";
import PropTypes from "prop-types";
import TextComponent from "components/TextComponent";
import TextRadioComponent from "components/TextRadioComponent";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
function AccessLevelSettings({ values, setValues }) {
  const [errors, setErrors] = useState({});
  const saveBusinessSettings = async () => {
    let savedObj = {
      role_switch: values.role_switch,
      higher_role_level: parseInt(values.higher_role_level),
      min_teacher_access: parseInt(values.min_teacher_access),
      min_student_access: parseInt(values.min_student_access),
    };
    if (values.min_learning_credits) {
      savedObj = {
        ...savedObj,
        min_learning_credits: parseInt(values.min_learning_credits),
      };
    }
    if (values.max_learning_credits) {
      savedObj = {
        ...savedObj,
        max_learning_credits: parseInt(values.max_learning_credits),
      };
    }
    await CallAPI(
      "UPDATE_BUSINESS_SETTING",
      savedObj,
      values.id,
      null,
      null,
      null
    );
  };
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <TextRadioComponent
            name="role_switch"
            placeholder={`role_switch_${
              values["role_switch"] ? values["role_switch"] : "false"
            }`}
            label="role_switch"
            value={values["role_switch"] ?? false}
            setValue={setValues}
            error={errors["role_switch"] ?? ""}
            classes={""}
            required={true}
          />
          <TextComponent
            name="higher_role_level"
            placeholder="higher_role_level"
            label="higher_role_level"
            value={values["higher_role_level"] ?? ""}
            setValue={setValues}
            error={errors["higher_role_level"] ?? ""}
            classes={""}
            required={true}
            type="number"
          />
          <TextComponent
            name="min_teacher_access"
            placeholder="min_teacher_access"
            label="min_teacher_access"
            value={values["min_teacher_access"] ?? ""}
            setValue={setValues}
            error={errors["min_teacher_access"] ?? ""}
            classes={""}
            required={true}
            type="number"
          />
          <TextComponent
            name="min_student_access"
            placeholder="min_student_access"
            label="min_student_access"
            value={values["min_student_access"] ?? ""}
            setValue={setValues}
            error={errors["min_student_access"] ?? ""}
            classes={""}
            required={true}
            type="number"
          />
          <TextComponent
            name="min_learning_credits"
            placeholder="min_learning_credits"
            label="min_learning_credits"
            value={values["min_learning_credits"] ?? ""}
            setValue={setValues}
            error={errors["min_learning_credits"] ?? ""}
            classes={""}
            required={true}
            type="number"
          />
          <TextComponent
            name="max_learning_credits"
            placeholder="max_learning_credits"
            label="max_learning_credits"
            value={values["max_learning_credits"] ?? ""}
            setValue={setValues}
            error={errors["max_learning_credits"] ?? ""}
            classes={""}
            required={true}
            type="number"
          />
        </div>
        <div>
          <ButtonComponent
            text="update_settings"
            variant="blue-1 ms-auto"
            onClick={saveBusinessSettings}
            loading={false}
            disabled={false}
          />
        </div>
      </div>
    </>
  );
}

AccessLevelSettings.propType = {
  values: PropTypes.array.isRequired,
  setVAlues: PropTypes.func.isRequired,
  //   fetchUpdatedDetails: PropTypes.func.isRequired,
};
export default AccessLevelSettings;
