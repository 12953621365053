import React, { useState } from "react";
import PropTypes from "prop-types";
import TextComponent from "components/TextComponent";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import { useTranslation } from "react-i18next";
import PaymentArrow from "assets/images/general/payment-arrow.svg";

function PaymentSettings({ values, setValues }) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [selectedPaymentType, setSelectedPaymentType] = useState("stripe");
  const savePaymentSettings = async () => {
    await CallAPI(
      "UPDATE_BUSINESS_SETTING",
      {
        stripe_access_key: values.stripe_access_key,
        stripe_secret_key: values.stripe_secret_key,
        stripe_prod_key: values.stripe_prod_key,
      },
      values.id,
      null,
      null,
      null
    );
  };
  return (
    <div className="payment-settings">
      <div className="single-item">
        <div className="title" onClick={() => setSelectedPaymentType("stripe")}>
          <span>{t("texts.stripe_settings")}</span>
          <img
            src={PaymentArrow}
            alt="payment arrow"
            className={`${
              selectedPaymentType === "stripe" ? "" : "rotate-180"
            }`}
          />
        </div>
        {selectedPaymentType === "stripe" && (
          <div className="my-10 box-width-flex">
            <div>
              <TextComponent
                name="stripe_access_key"
                placeholder="stripe_access_key"
                label="stripe_access_key"
                value={values["stripe_access_key"] ?? ""}
                setValue={setValues}
                error={errors["stripe_access_key"] ?? ""}
                classes={"mx-auto"}
                required={true}
              />
            </div>
            <div>
              <TextComponent
                name="stripe_secret_key"
                placeholder="stripe_secret_key"
                label="stripe_secret_key"
                value={values["stripe_secret_key"] ?? ""}
                setValue={setValues}
                error={errors["stripe_secret_key"] ?? ""}
                classes={"mx-auto"}
                required={true}
              />
            </div>
            <div>
              <TextComponent
                name="stripe_prod_key"
                placeholder="stripe_prod_key"
                label="stripe_prod_key"
                value={values["stripe_prod_key"] ?? ""}
                setValue={setValues}
                error={errors["stripe_prod_key"] ?? ""}
                classes={"mx-auto"}
                required={true}
              />
            </div>
            <div>
              <ButtonComponent
                text="update_settings"
                variant="blue-1 ms-auto"
                onClick={savePaymentSettings}
                loading={false}
                disabled={false}
              />
            </div>
          </div>
        )}
      </div>
      <div className="single-item">
        <div
          className="title"
          onClick={() => setSelectedPaymentType("pay_stack")}
        >
          <span>{t("texts.pay_stack_settings")}</span>
          <img
            src={PaymentArrow}
            alt="payment arrow"
            className={`${
              selectedPaymentType === "pay_stack" ? "" : "rotate-180"
            }`}
          />
        </div>
        {selectedPaymentType === "pay_stack" && (
          <div className="my-10 box-width-flex">
            <div>
              <TextComponent
                name="pay_stack_access_key"
                placeholder="pay_stack_access_key"
                label="pay_stack_access_key"
                value={values["pay_stack_access_key"] ?? ""}
                setValue={setValues}
                error={errors["pay_stack_access_key"] ?? ""}
                classes={"mx-auto"}
                required={true}
              />
            </div>
            <div>
              <TextComponent
                name="pay_stack_secret_key"
                placeholder="pay_stack_secret_key"
                label="pay_stack_secret_key"
                value={values["pay_stack_secret_key"] ?? ""}
                setValue={setValues}
                error={errors["pay_stack_secret_key"] ?? ""}
                classes={"mx-auto"}
                required={true}
              />
            </div>
            <div>
              <ButtonComponent
                text="update_settings"
                variant="blue-1 ms-auto"
                onClick={savePaymentSettings}
                loading={false}
                disabled={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PaymentSettings.propType = {
  values: PropTypes.array.isRequired,
  setVAlues: PropTypes.func.isRequired,
  //   fetchUpdatedDetails: PropTypes.func.isRequired,
};
export default PaymentSettings;
