export const TagsTableHeader = [
  { key: "title", displayValue: "tags_list", sort: true },
];
export const ModalButtons = {
  confirmDelete: {
    text: "delete_record",
    buttons: [
      {
        text: "delete",
        variant: "red-1",
        onClick: "deleteTag",
        loading: false,
        disabled: false,
      },
      {
        text: "cancel",
        variant: "white-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  recordDelete: {
    text: "record_deleted",
    buttons: [
      {
        text: "ok",
        variant: "blue-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
  listing403: {
    text: "403_error_msg",
    buttons: [
      {
        text: "ok",
        variant: "red-1",
        onClick: "closeModal",
        loading: false,
        disabled: false,
      },
    ],
  },
};
