import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DisplayHtml from "views/DisplayHtml";
import DocumentImage from "assets/images/general_old/document-solid.svg";
import { getImagePreviewUrl } from "config/config";
import DEFAULT_IMG from "assets/images/general_old/default-image.svg";

function Preview({ values }) {
  const [imageUrl, setImageUrl] = useState("");
  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  useEffect(() => {
    if (values && values.banner && values.banner.id)
      getPreviewImg(values.banner.id);
  }, [values]);
  return (
    <div className="preview">
      <div className="item">
        <img
          src={
            values.banner && values.banner.id
              ? imageUrl
              : values.banner
              ? URL.createObjectURL(values.banner)
              : DEFAULT_IMG
          }
          alt="Unit Image"
        />
        <div className="top">
          <div className="title">
            <span className="text-gray-800 fw-bold">Title:</span>
            <span className="text-gray-800">{values.title}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Category:</span>
            <span className="text-gray-800">{values.category_id}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Enrolled Points:</span>
            <span className="text-gray-800">{values.enroll_credits}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Reward Points:</span>
            <span className="text-gray-800">{values.reward_credits}</span>
          </div>
          <div>
            <span className="text-gray-800 fw-bold">Description:</span>
            <span className="text-gray-800">
              <DisplayHtml html={values.detail} />
            </span>
          </div>
        </div>
      </div>
      {values.pre_req_courses.length > 0 && (
        <>
          <div className="item">
            <span className="text-gray-800 fw-bold">Pre Requisite Courses</span>
            <div>
              <span className="text-gray-800">
                {values.pre_req_courses
                  .map((singleCourse) => singleCourse.title)
                  .join(", ")}
              </span>
            </div>
          </div>
          <hr className="light" />
        </>
      )}
      <div className="item">
        <span className="text-gray-800 fw-bold">Tags</span>
        <div>
          <span className="text-gray-800">
            {values.tags.map((singleTag) => singleTag.title).join(",")}
          </span>
        </div>
      </div>
      <hr className="light" />
      <div className="item">
        <span className="text-gray-800 fw-bold">Modules</span>
        <div>
          <span className="text-gray-800">
            {values.modules.map((singleModule) => singleModule.title).join(",")}
          </span>
        </div>
      </div>
      <hr className="light" />
      <div className="item">
        <span className="text-gray-800 fw-bold">What will you learn</span>
        <div className="d-flex flex-column">
          {values.learning_points.map((singleLearningPoint, index) => {
            return (
              <span className="text-gray-800" key={index}>
                {singleLearningPoint.text}
              </span>
            );
          })}
        </div>
      </div>
      <hr className="light" />
      <div className="item">
        <span className="text-gray-800 fw-bold">Documents & Notes</span>
        <div className="listing-item documents">
          {values.documents.map((singleDocument, index) => {
            return (
              <div className="detail document" key={index}>
                <img src={DocumentImage} alt="detail icon" />
                <div>
                  <span className="title">{singleDocument.name}</span>
                  <span className="size">{singleDocument.size}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <hr className="light" />
      <div className="item">
        <span className="text-gray-800 fw-bold">Reference Urls</span>
        <div className="listing-item urls">
          {values.urls.map((singleUrl, index) => {
            return (
              <span className="detail urls" key={index}>
                {singleUrl.text}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}
Preview.propTypes = {
  values: PropTypes.object.isRequired,
};
export default Preview;
