import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import TextAreaComponent from "components/TextAreaComponent";
import TextComponent from "components/TextComponent";
import { CompetencyLevels } from "config/config";
import SingleFileDropZoneComponent from "components/SingleFileDropZoneComponent";

function BasicDetails({ values, errors, updateFormValues }) {
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <TextComponent
            name={"title"}
            label={"enter_title"}
            placeholder={"title"}
            value={values.title}
            setValue={updateFormValues}
            classes={""}
            error={errors["title"] ?? ""}
            required={true}
          />
          <TextComponent
            name={"reward_credits"}
            label={"reward_credits"}
            placeholder={"reward_credits"}
            value={values.reward_credits}
            setValue={updateFormValues}
            classes={""}
            error={errors["reward_credits"] ?? ""}
            type="number"
            required={true}
          />
          <SelectComponent
            name={"competency_level"}
            label={"competency_level"}
            value={values.competency_level}
            onChange={updateFormValues}
            classes={""}
            optionsList={CompetencyLevels}
            error={errors["competency_level"] ?? ""}
            required={true}
          />
        </div>
        <div>
          <SingleFileDropZoneComponent
            name={"banner"}
            label={"banner_image"}
            value={values.banner}
            setValue={updateFormValues}
            classes={"half"}
            isMultipleAllowed={false}
            type={"image"}
            required={false}
          />
        </div>
        <div>
          <TextAreaComponent
            name={"detail"}
            label={"description"}
            placeholder={"description"}
            value={values.detail}
            error={errors["detail"] ?? ""}
            setValue={updateFormValues}
            classes={"w-100"}
            required={true}
          />
        </div>
      </div>
    </>
  );
}
BasicDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default BasicDetails;
