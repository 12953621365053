import React, { Fragment } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isUserHasRole, isUserLoggedIn } from "recoil/Auth";

const NonAuthenticatedLayout = () => {
  const location = useLocation();
  const isLogin = useRecoilValue(isUserLoggedIn);
  const userHasRole = useRecoilValue(isUserHasRole);

  return (
    <Fragment>
      <div className="non-auth-layout">
        {isLogin && userHasRole ? (
          // <Navigate to="/dashboard" replace state={{ from: location }} />
          <Navigate to="/program" replace state={{ from: location }} />
        ) : (
          <>
            <div className="content">
              <h1 className="theme-font-kaisei-tokumin-bold">
                Lead <br /> Your Field
              </h1>
              <span className="theme-font-kaisei-tokumin-regular">
                Make a Global Impact
              </span>
            </div>

            <Outlet />
          </>
        )}
      </div>
    </Fragment>
  );
};

export default NonAuthenticatedLayout;
