import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderComponent from "components/LoaderComponent";
import { getImagePreviewUrl } from "config/config";
import { CallAPI } from "actions/General";
import DEFAULT_IMG from "assets/images/general_old/default-image.svg";
import DocumentImage from "assets/images/general_old/document-solid.svg";
import DisplayHtml from "views/DisplayHtml";
import ButtonComponent from "components/ButtonComponent";
import allFunction, { convertSeconds } from "actions/TableUtils";
import { ViewCourseDetailTabs } from "./utils";
import TabsComponent from "components/TabsComponent";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function CourseDetailView() {
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);

  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderType, setLoaderType] = useState("get_list");
  const [selectedTab, setSelectedTab] = useState(1);
  const [imageUrl, setImageUrl] = useState("");

  const [enrolledObj, setEnrolledObj] = useState({});

  const getCourseDetails = async (record_id) => {
    if (record_id) {
      setLoaderType("get_list");
      let result = await CallAPI(
        "GET_COURSE_DETAIL",
        null,
        record_id,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let courseObj = result.data;
        let prevValues = {
          title: courseObj.title,
          version: courseObj.version,
          subscription: courseObj.subscription
            ? courseObj.subscription.title
            : "",
          banner: courseObj.banner_image ? courseObj.banner_image.id : null,
          reward_credits: courseObj.reward_credits,
          enroll_credits: courseObj.enroll_credits,
          category_name: courseObj.category.title,
          language: courseObj.language,
          slug: courseObj.slug,
          status: courseObj.status,
          detail: courseObj.description,
          is_free: courseObj.is_free ? "1" : "0",
          short_description: courseObj.short_description,
          completion_duration: courseObj.completion_duration,
          pre_req_courses: courseObj.pre_req_courses
            ? courseObj.pre_req_courses
            : [],
          quiz_url: courseObj.quiz_url,
          completed_percentage:
            courseObj.certificate_criteria.completed_percentage,
          quiz_percentage: courseObj.certificate_criteria.quiz_percentage,
          validity_period: courseObj.certificate_criteria.validity_period,
          validity_type: courseObj.certificate_criteria.validity_type,
          cert_description: courseObj.certificate_criteria.description,
          payment_type: courseObj.payment_type,
          author_name: courseObj.author_name,
          // banner: courseObj.banner_image_id
          //   ? { id: courseObj.banner_image_id }
          //   : null,
          tags: courseObj.tags.map((singleTag) => {
            return singleTag.title;
          }),
          learning_points: courseObj.learning_points.map(
            (singleLearningPoint) => {
              return singleLearningPoint.title;
            }
          ),
          urls: courseObj.urls.map((singleUrl) => {
            return singleUrl.title;
          }),
          modules: courseObj.course_modules.map((singleModule) => {
            return singleModule.title;
          }),
          documents: courseObj.documents.map((singleDocument) => {
            return {
              id: singleDocument.id,
              media_id: singleDocument.id,
              name: singleDocument.name,
              size: singleDocument.size,
            };
          }),
          enrollmentDetails: courseObj.enrollmentDetails ?? null,
          programEnrollmentDetails: courseObj.programEnrollmentDetails ?? null,
        };
        setValues({
          ...prevValues,
        });
        if (prevValues.banner) {
          getPreviewImg(prevValues.banner);
        }
        setEnrolledObj({
          programEnrollmentDetails: courseObj.programEnrollmentDetails ?? null,
          type: "course",
          course_id: courseObj.course_id,
          version_id: record_id,
          title: courseObj.title,
          payment_type: courseObj.is_free
            ? { type: "free" }
            : courseObj.subscription
            ? { type: "subscription", slug: courseObj.subscription.slug }
            : { type: "pay", method: courseObj.payment_type },
        });
      }
    }
  };
  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getCourseDetails(location.state.record_id);
  }, [location]);

  const publishCourse = async () => {
    setLoaderType("publish");
    const result = await CallAPI(
      "PUBLISH_COURSE",
      {
        status: "PUBLISHED",
      },
      location.state.record_id,
      setLoader,
      null,
      null
    );
    if (result.status) getCourseDetails(location.state.record_id);
  };

  const moveToPage = (page) => {
    navigate(page, { state: enrolledObj });
  };
  const moveToModulesPage = (page) => {
    navigate(page, {
      state: {
        course_id: values.enrollmentDetails.version_id,
        enrollment_id: values.enrollmentDetails.enrollment_id,
      },
    });
  };

  return (
    <div className="module-detail-view preview">
      {loader && loaderType === "get_list" ? (
        <LoaderComponent classes="mx-4" />
      ) : (
        <>
          <div className="p-5 pt-18 position-relative">
            <div className="item basic pt-5">
              <img
                src={values.banner ? imageUrl : DEFAULT_IMG}
                alt="Unit Image"
              />
              <div className={`top ${userRole === "STUDENT" && "add-border"} `}>
                <div>
                  <span className="text-gray-800 fw-bold">Title:</span>
                  <span className="text-gray-800">{values.title}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Category:</span>
                  <span className="text-gray-800">{values.category_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">language:</span>
                  <span className="text-gray-800">{values.language}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Author Name:</span>
                  <span className="text-gray-800">{values.author_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Subscription:</span>
                  <span className="text-gray-800">{values.subscription}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Tags:</span>
                  <span className="text-gray-800">
                    {values.tags.join(", ")}
                  </span>
                </div>
              </div>
              <div className={`top ${userRole === "STUDENT" && "add-border"} `}>
                <div>
                  <span className="text-gray-800 fw-bold">Status:</span>
                  <span className="text-gray-800">
                    {allFunction.displayUnitStatus(values.status)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Enroll Credits:</span>
                  <span className="text-gray-800">{values.enroll_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Reward Credits:</span>
                  <span className="text-gray-800">{values.reward_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Payment Type:</span>
                  <span className="text-gray-800">
                    {allFunction.getSubscriptionType(values.payment_type)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">is Free:</span>
                  <span className="text-gray-800">
                    {values.is_free === "1" ? "True" : "False"}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Slug:</span>
                  <span className="text-gray-800">{values.slug}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">
                    Quiz Percentage:
                  </span>
                  <span className="text-gray-800">
                    {values.quiz_percentage}
                  </span>
                </div>
              </div>
            </div>
            <div className="actions-buttons">
              {userRole === "BUSINESS_ADMIN" ? (
                <div>
                  <ButtonComponent
                    text="publish_course"
                    onClick={publishCourse}
                    variant="blue-1"
                    loading={loader && loaderType === "publish"}
                    disabled={
                      allFunction.isItemPublished(values.status) ||
                      (loader && loaderType === "publish")
                    }
                  />
                </div>
              ) : (
                userRole === "STUDENT" && (
                  <>
                    {/* {values.enrollmentDetails &&
                      values.enrollmentDetails.allowApplyForQuiz && (
                        <div>
                          <ButtonComponent
                            text="apply_for_quiz"
                            onClick={publishCourse}
                            variant="orange-1 type-2"
                            loading={false}
                            disabled={false}
                          />
                        </div>
                      )} */}
                    {values.enrollmentDetails &&
                      (values.enrollmentDetails.timeToStart > 0 ? (
                        <span className="theme-font-inter-medium px-4 starts-in-size">
                          {"Starts In " +
                            convertSeconds(
                              values.enrollmentDetails.timeToStart
                            )}
                        </span>
                      ) : (
                        <>
                          <div>
                            <ButtonComponent
                              text="view_notes"
                              onClick={() =>
                                moveToModulesPage("/enrollment_modules")
                              }
                              variant="blue-1 type-2"
                              loading={false}
                              disabled={false}
                            />
                          </div>
                          {/* <div>
                            <ButtonComponent
                              text="view_progress"
                              onClick={() => moveToPage("/enrollment_progress")}
                              variant="blue-9 type-2"
                              loading={false}
                              disabled={false}
                            />
                          </div> */}
                        </>
                      ))}
                    {/* <div>
                      <ButtonComponent
                        text="qa"
                        onClick={publishCourse}
                        variant="blue-10 type-2"
                        loading={false}
                        disabled={false}
                      />
                    </div> */}

                    {!values.enrollmentDetails && (
                      <div>
                        <ButtonComponent
                          text="enroll"
                          onClick={() => moveToPage("/enroll_schedules")}
                          variant="blue-9 type-2"
                          loading={false}
                          disabled={false}
                        />
                      </div>
                    )}
                  </>
                )
              )}
            </div>
          </div>
          <div className="py-5">
            <TabsComponent
              tabs={ViewCourseDetailTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              type={"start"}
            >
              <div className="p-5 tab-item-detail item">
                {selectedTab === 1 ? (
                  <>
                    <span className="text-gray-800 fw-bold">
                      Short Description
                    </span>
                    <span className="text-gray-800">
                      {values.short_description}
                    </span>
                    <hr className="light" />
                    <span className="text-gray-800 fw-bold">Description</span>
                    <span className="text-gray-800">
                      {<DisplayHtml html={values.detail} />}
                    </span>
                  </>
                ) : selectedTab === 2 ? (
                  <ul>
                    {values.learning_points.map(
                      (singleLearningPoint, index) => {
                        return (
                          <li className="text-gray-800" key={index}>
                            {singleLearningPoint}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <>
                    <span className="text-gray-800 fw-bold">Modules:</span>
                    <span className="text-gray-800">
                      {values.modules.join(", ")}
                    </span>
                    <hr className="light" />
                    <span className="text-gray-800 fw-bold">Tags:</span>
                    <span className="text-gray-800">
                      {values.tags.join(", ")}
                    </span>
                    <hr className="light" />
                    <span className="text-gray-800 fw-bold">
                      Documents & Notes
                    </span>
                    {values.documents.map((singleDocument, index) => {
                      return (
                        <div className="detail document" key={index}>
                          <img src={DocumentImage} alt="detail icon" />
                          <div>
                            <span className="title">{singleDocument.name}</span>
                            <span className="size">{singleDocument.size}</span>
                          </div>
                        </div>
                      );
                    })}
                    <hr className="light" />
                    <div className="item">
                      <span className="text-gray-800 fw-bold">
                        Reference Urls:
                      </span>
                      <div className="listing-item urls">
                        {values.urls.map((singleUrl, index) => {
                          return (
                            <span className="detail urls" key={index}>
                              {singleUrl}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </TabsComponent>
          </div>
        </>
      )}
    </div>
  );
}

export default CourseDetailView;
