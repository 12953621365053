import React from "react";
import PropTypes from "prop-types";
import EditIcon from "assets/images/form/edit-icon.svg";
import DeleteIcon from "assets/images/form/delete-icon.svg";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function TagActions({ data, props }) {
  const userRole = useRecoilValue(getUserRole);
  const editTag = () => {
    props.setEditObj(data);
  };
  const deleteTag = async () => {
    props.deleteTagConfirmation(data.id);
  };

  return (
    <>
      {userRole === "BUSINESS_ADMIN" && (
        <div className="d-flex gap-3 table-actions">
          <img onClick={editTag} src={EditIcon} alt="edit-icon" />
          <img onClick={deleteTag} src={DeleteIcon} alt="edit-icon" />
        </div>
      )}
    </>
  );
}
TagActions.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
export default TagActions;
