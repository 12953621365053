import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DisplaySVG from "./DisplaySVG";

function TabsComponent({ tabs, selectedTab, setSelectedTab, children, type }) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`stepper stepper-links d-flex flex-column ${
          type === "start" ? "pt-5" : "pt-10"
        }`}
      >
        <div
          className={`stepper-nav mb-5 ${
            type === "start" ? "mx-0 justify-content-start" : ""
          }`}
        >
          {tabs.map((singleTab, index) => (
            <div
              key={index}
              className={`stepper-item cursor-pointer ${
                index + 1 === selectedTab
                  ? "current"
                  : index + 1 < selectedTab
                  ? "completed"
                  : "pending"
              }`}
              data-kt-stepper-element="nav"
              onClick={() => setSelectedTab(index + 1)}
            >
              <h3 className="stepper-title">
                {singleTab.img && (
                  <DisplaySVG
                    path={singleTab.img}
                    classes={"svg-icon-2"}
                    svgClassName=""
                  />
                )}
                {t("tabs." + singleTab.displayValue)}
              </h3>
            </div>
          ))}
        </div>
      </div>
      {children}
    </>
  );
}
TabsComponent.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  children: PropTypes.any,
  type: PropTypes.string,
};
export default TabsComponent;
