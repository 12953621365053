import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import TableComponent from "components/TableComponent";
import { DefaultPaginationSize } from "config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalButtons, ProgramTableHeader, ProgramGridHeader } from "./utils";
import GridView from "components/GridView";
import CustomModal from "components/CustomModal";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";

function Program() {
  const navigate = useNavigate();
  const userRole = useRecoilValue(getUserRole);
  const [totalRecords, setTotalRecords] = useState(0);
  const [programs, setPrograms] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(DefaultPaginationSize);
  const [displayType, setDisplayType] = useState("list");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");
  const [deactivatedId, setDeactivatedId] = useState("");
  const [deactivatedType, setDeactivatedType] = useState("");

  const getProgramsList = async () => {
    setLoaderType("get_listing");
    let result = await CallAPI(
      "FETCH_PROGRAMS_LIST",
      { relations: ["tags"], page, pageSize: limit },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setPrograms(result.data.programs);
      setTotalRecords(result.data.total);
    } else if (result.error === 403) {
      setIsModalOpen(true);
      setModalVariant("error");
      setModalType("listing403");
    }
  };
  useEffect(() => {
    getProgramsList();
  }, [page, limit]);

  const addCourse = () => {
    navigate("/add_program");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deactivateProgramConfirmation = async (id, is_active) => {
    setDeactivatedId(id);
    setDeactivatedType(!is_active);
    setIsModalOpen(true);
    setModalVariant("confirm");
    setModalType(is_active ? "confirmDelete" : "confirmActive");
  };

  const deactivateProgram = async () => {
    setLoaderType("delete");
    let result = await CallAPI(
      "DE_ACTIVATE_PROGRAM",
      { is_active: deactivatedType },
      deactivatedId,
      setLoader,
      setApiError,
      null
    );
    setLoaderType("");
    if (result.status) {
      getProgramsList();
      setModalVariant("success");
      setModalType(!deactivatedType ? "recordDelete" : "recordActivated");
    } else {
      setModalVariant("error");
      setModalType("deletionError");
    }
  };
  const actionOnDeactivatedProgram = () => {
    setIsModalOpen(true);
    setModalVariant("error");
    setModalType("deactivateActionError");
  };
  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card mb-4">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"program_listing"}
          setDisplayType={setDisplayType}
          displayType={displayType}
          addItem={userRole === "BUSINESS_ADMIN" ? addCourse : ""}
        />
        <div className={"card-body py-4"}>
          {displayType === "list" && (
            <TableComponent
              data={programs}
              header={ProgramTableHeader}
              type="program"
              props={{
                deactivateProgramConfirmation,
                actionOnDeactivatedProgram,
              }}
            />
          )}
        </div>
      </div>
      {displayType === "grid" && (
        <GridView data={programs} columns={ProgramGridHeader} />
      )}
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal, deactivateProgram }}
        />
      )}
    </>
  );
}

export default Program;
