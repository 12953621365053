export const LoginResponse = (data) => {
  const authObject = {
    authToken: data.access_token,
    email: data.email,
    id: "",
    role: data.role,
    businessesList: data.business_list,
    details: {
      user_name: data.user_name,
      business_name: data.business_name,
      name: data.name,
      email: data.email,
      role_name: data.role_name,
      profile_name: data.profile_name,
      department_name: data.department_name,
      job_title: data.job_title,
      about: data.about,
      avatar: data.avatar,
      total_credits: data.total_credits,
    },
  };
  return authObject;
};
