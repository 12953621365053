import React from "react";
import { useRecoilValue } from "recoil";
import { getUserRole } from "recoil/Auth";
import AuthSettings from "./AuthSettings";
import BusinessSettings from "./BusinessSettings";

function Settings() {
  const userRole = useRecoilValue(getUserRole);
  return (
    <>
      {userRole === "SUPER_ADMIN" ? <AuthSettings /> : <BusinessSettings />}
    </>
  );
}

export default Settings;
